<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" >
      <v-card class="delete-dialog">
        <v-card-title>
          <span class="text-h5 text-capitalize">{{ promptTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            {{ promptMessage }}
            <div class="business_section">
              <div class="business_delete">{{ business_delete }} <span>{{ business_delete_main }}</span> </div>
              <div class="business_remain">{{ business_remain }} <span>{{ business_remain_main }}</span> </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <span class="business_caption">{{business_msg}}</span>
          <v-spacer></v-spacer>
          <v-btn color="green-darken-1" variant="text" @click="agree()">YES</v-btn>
          <v-btn color="grey-darken-1" variant="text" @click.stop="show=false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
  .delete-dialog {
    .v-card-title {
      .text-h5 {
        font-family: $TBWAGrotesk_Bold;
      }
    }
    .v-card-text {
      font-family: $Archivo;
      padding-top: 0 !important;
      .v-container {
        color: rgba(0,0,0,.6);
        font-size: .875rem;
        font-weight: 400;
        line-height: 1.375rem;
        letter-spacing: .0071428571em;
      }
    }
    .v-card-actions {
      padding: 0 24px 20px;
      button {
        font-family: $TBWAGrotesk_Bold;
      }
    }
  }
</style>
<script>
export default {
  props: {
    value: Boolean,
    message: null,
    business_will: null,
    business_will1: null,
    business_delete_text: null,
    business_remain_text: null,
    title: null,
    msg: null,
  },
  computed: {
    show: {
      get (){
        return this.value;
      },
      set (value){
        this.$emit("update:value", value);
      }
    },
    promptMessage: function(){
      return this.message ? this.message : 'Are you sure you want to delete this record?';
    },
    business_delete: function(){
      return this.business_will ? this.business_will : '';
    },
    business_remain: function(){
      return this.business_will1 ? this.business_will1 : '';
    },
    business_msg: function(){
      return this.msg ? this.msg : '';
    },
    business_delete_main: function(){
      return this.business_delete_text ? this.business_delete_text : '';
    },
    business_remain_main: function(){
      return this.business_remain_text ? this.business_remain_text : '';
    },
    promptTitle: function(){
      return this.title ? this.title : 'Delete';
    }
  },
  methods: {
    agree(){
      this.$emit("agree", true);
    }
  },
}
</script>
