<template>
  <div v-if="false" class="maintenance">
    <p>The server is under maintenance, we will come back!!</p>
  </div>
  <v-app v-else class=" " :class="{ homePage : this.$route.name == 'Home' }">
    <v-img :aspect-ratio="16/9" src="~@/assets/images/bg_home.png" style="height: 100%" cover></v-img>
    <v-app-bar>
      <div class="banner">
        <div v-if="display_opp" class="opp_logo"><router-link :to="{ name: 'OpportunityList'}"></router-link></div>
        <div v-else class="logo"><router-link :to="{ name: 'Home'}"></router-link></div>
        <div class="nav">
          <div>
            <ul v-if="display_opp" class="nav-items opphub-local-nav-items" ref="navItemsRef">
              <li v-if="is_opp_user"><a href="https://app.powerbi.com/links/8jkdRkWEA8?ctid=41eb501a-f671-4ce0-a5bf-b64168c3705f&pbi_source=linkShare" target="_black" class="nomination"><svg-icon icon-class="PBI-Dashboard"></svg-icon>PBI <br>Dashboard</a></li>
              <li v-if="is_opp_user"><router-link :to="{ name: 'OpportunityList'}" class="nomination"><svg-icon icon-class="oppsListing"></svg-icon>Opps <br>Listing</router-link></li>
              <li v-if="is_opp_user"><router-link :to="{ name: 'OpportunityKanban'}" class="nomination"><svg-icon icon-class="kanBan"></svg-icon>Kan Ban</router-link></li>
              <li v-if="can_create"><router-link :to="{ name: 'OpportunityCreate'}" class="nomination"><svg-icon icon-class="newOpportunity"></svg-icon>New Opportunity</router-link></li>
              <li v-if="is_opp_user"><router-link :to="{ name: 'MyAgency' }" class="nomination icon-agency">My Agency</router-link></li>
              <li v-if="is_opp_user"><router-link :to="{ name: 'MyDashboard' }" class="nomination icon-mine-1">My Dashboard</router-link></li>
              <li v-if="show_hfm"><router-link :to="{ name: 'HFM' }" class="nomination"><svg-icon icon-class="Hfm"></svg-icon>HFM</router-link></li>
              <li v-if="is_opp_user && showHelp">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  location="end center"
                >
                  <template v-slot:activator="{ props }">
                    <div v-bind="props" @click="handleOutsideClick" ref="menuButtonRef" class="nomination" :class="{ 'help-active': helpShow }" ><svg-icon icon-class="help"></svg-icon>Help And Resources</div>
                  </template>
                  <Help/>
                </v-menu>
                <div class="help-model" v-show="menu"></div>
              </li>
              <li class="menu-placeholder"></li>
              <!-- <li>
                <router-link :to="{ name: 'OpportunityFlow'}">
                  <svg-icon icon-class="UserGuideline" class="menu-color-transform"></svg-icon>User Guideline
                </router-link>
              </li> -->
            </ul>
            <ul v-else class="nav-items">
              <li><router-link :to="{ name: 'AgencyList' }" class="agency icon-office-1">Agencies</router-link></li>
              <li><router-link :to="{ name: 'CollectionList' }" class="collection icon-collection">Collections</router-link></li>
              <li v-if="is_glp_leader"><router-link :to="{ name: 'NominationList' }" class="nomination icon-glp">GLP</router-link></li>
            </ul>
          </div>
          <div id="header">
            <ul class="search_container" v-if="!display_opp">
              <li><div class="icon-search"><input type="text" placeholder="Search for agencies or people…" @keyup.enter="onEnterInSearch" v-model="globalSearch"></div></li>
            </ul>
            <ul class="top_corner">
              <!-- <li><Help/></li> -->
              <li v-if="is_super_admin">
                <v-btn
                  class="mt-3"
                  min-width="0"
                  variant="text"
                  @click="linkTo('/leaders/')"
                >
                  <v-icon>mdi-account-cog</v-icon>
                </v-btn>
              </li>
              <!-- <li><Notification/></li> -->
              <li :class="{ talent_bg2 : this.$route.name == 'Home' }"><UserProfile/></li>
            </ul>
          </div>
        </div>
      </div>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main id="main">
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view :searchVal="search" @indexSearch="indexSearch"></router-view>
      </v-container>
    </v-main>
    <Loading/>
    <Snackbar/>
    <ScrollBar/>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import UserProfile from '@/components/bars/profile';
//  import Notification from '@/components/bars/notification';
//  import Help from '@/components/bars/help';
import ScrollBar from '@/components/bars/scrollBar';
import Loading from '@/components/common/loading';
import Snackbar from '@/components/common/snackbar';
import Help from '@/components/common/helpDialog';
export default {
  name: 'app',
  components: {
    UserProfile,
    //      Notification,
    ScrollBar,
    Loading,
    Snackbar,
    Help
  },
  data(){
    return {
      authenticated: false,
      globalSearch: '',
      search: '',
      helpShow: false,
      helpDialog: false,
      menu: false,
      prevElement: null,
      shouldNavigate: false,
      showHelp: false,
    }
  },
  async created(){
    this.isAuthenticated();
    this.showHelp = true;
  },
  mounted() {
    document.addEventListener('click', this.handleGlobalClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleGlobalClick);
  },
  computed: {
    ...mapGetters(['permissions']),
    is_glp_leader: function(){
      return this.permissions.is_super_admin || this.permissions.is_glp_leader
    },
    is_opp_user: function(){
      return this.permissions.is_super_admin || this.permissions.is_opp_leader || this.permissions.is_opp_reporter || this.permissions.is_opp_support_team || this.permissions.is_opp_core_team || this.permissions.is_opp_viewer || this.permissions.is_opp_finance
    },
    is_super_admin: function(){
      return this.permissions.is_super_admin
    },
    can_create: function() {
      return this.permissions.is_super_admin || this.permissions.is_opp_leader || this.permissions.is_opp_reporter
    },
    display_opp: function() {
      return window.location.href.indexOf('opphub') != -1
    },
    show_hfm: function() {
      return this.permissions.is_super_admin || this.permissions.is_opp_finance
    },
  },
  watch: {
    // Everytime the route changes, check for auth status
    '$route'(to) {
      'isAuthenticated';
      if (to.name != 'Search') {
        this.globalSearch = null;
      }
      if (to.name == 'OpportunityFlow') {
        this.menu = false;
        this.helpShow = true;
      } else {
        this.helpShow = false;
      }
    },

    menu(newValue) {
      this.helpShow = !this.helpShow;
      const ulElement = this.$refs.navItemsRef;
      const liElements = ulElement.querySelectorAll('li');
      if(newValue) {
        this.helpShow = true
        liElements.forEach(liElement => {
          const anchorElement = liElement.querySelector('a');
          if (anchorElement && (anchorElement.classList.contains('router-link-active') || anchorElement.classList.contains('router-link-exact-active'))) {
            anchorElement.classList.remove('router-link-active')
            anchorElement.classList.remove('router-link-exact-active')
            this.prevElement = anchorElement;
          }
        });
      } else {
        this.helpShow = false;
        liElements.forEach(liElement => {
          const anchorElement = liElement.querySelector('a');
          if (anchorElement && this.prevElement && anchorElement.href == this.prevElement.href && this.shouldNavigate && this.$route.name != "OpportunityFlow") {
            anchorElement.classList.add('router-link-active');
            anchorElement.classList.add('router-link-exact-active')
          }
        });
      }
    },
  },
  methods: {
    ...mapGetters('oidcStore', [
      'oidcIsAuthenticated',
      'oidcAccessToken',
    ]),
    async isAuthenticated () {
      this.authenticated = await this.oidcIsAuthenticated()
    },
    handleGlobalClick() {
      this.shouldNavigate = true;
    },
    handleOutsideClick(){
      this.shouldNavigate = true;
    },
    linkTo(link) {
      this.$router.push(link).catch(()=>{});
    },
    onEnterInSearch: function(){
      this.search = this.globalSearch
      if (this.$route.name != 'Search'){
        this.$router.push("/search");
      }
    },
    indexSearch: function(val){
      this.search = val;
      if (this.$route.name != 'Search'){
        this.$router.push("/search");
      }
    },
  },
}
</script>
<style lang="scss">
  @import './assets/styles/app/opportunity.scss';
</style>

<style lang="scss" scoped>
  @import './assets/styles/app/app.scss';
  :deep(.button_1) {
    margin-top: 0px !important;
    button.v-btn {
      max-height: inherit !important;
    }
  }
  :deep(.talent_bg1) {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  :deep(.talent_bg) {
    margin-top: -4px !important;
    margin-bottom: 0px !important;
  }
  .maintenance {
    height: 100%;
    width: 100%;
    background: url('~@/assets/images/maintenance.png') no-repeat center 40%;
    background-size: auto 120px;
    position: relative;

    p{
      text-align: center;
      color: #999;
      font-size: 14px;
      position: absolute;
      bottom: calc(60% - 120px);
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .help-model {
    background: rgba(83, 86, 90, 0.83);
    position: fixed;
    top: 60px;
    left: 80px;
    z-index: 1;
    width: calc(100vw - 80px);
    height: calc(100vh - 60px);
    --v-overlay-anchor-origin: right center;
    transform-origin: left center;
  }
  .v-overlay {
    :deep(.v-overlay__content) {
      width: 310px !important;
      border-radius: 20px;
      .v-card {
        box-shadow: none;
      }
      .helpdialog-section {
        background: #F8F8F8;
        border-radius: 20px;
        padding: 30px 30px 50px 20px;
        .helpdialog-block {
          h4 {
            font-size: 12px;
            font-family: $TBWAGrotesk_Bold;
            color: #000;
            line-height: 14px;
            margin-bottom: 30px;
          }
          .helpdialog-list {
            .helpdialog-item {
              list-style: disc;
              margin-bottom: 45px;
              margin-left: 20px;
              .helpdialog-link {
                display: flex;
                align-items: center;
                span {
                  font-size: 12px;
                  color: #53565A;
                  line-height: 14px;
                  font-family: $TBWAGrotesk_Regular;
                  text-decoration: underline;
                  text-underline-position: under;
                  margin-right: 35px;
                  min-width: 129px;
                  display: inline-block;
                }
                img {
                  width: 22px;
                }
                .svg-icon {
                  width: 18px;
                  height: 18px;
                  fill: #FECC00;
                  color: #FECC00;
                }
              }
            }
          }
          .helpdialog-resources-link {
            margin-left: 10px;
            text-decoration: underline;
            text-underline-position: under;
            font-size: 12px;
            font-family: $TBWAGrotesk_Regular;
            font-weight: 500;
          }
        }
      }
    }
  }
</style>

<!------- Heap Part -->
<!-- <script type="text/javascript">
  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  if(process.env.VUE_APP_ENV == 'production'){
    heap.load("1707803435");
  }else{
    heap.load("2060875043");
  }
</script> -->
