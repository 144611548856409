<template>
  <div class="new_nomination_form_block">
    <dragable
      :list="nominationList"
      :group="nomination"
      filter=".unmover"
      @add="addNomination"
      item-key="(key) => key"
      ghostClass="ghost"
      chosenClass="chosen"
      @end="onUpdate"
    >
      <template #item="{ element, index }">
        <div v-if="element.nomination_id" class="new_nomination_form_area">
          <v-avatar start size="80" class="avatar">
            <div v-if="element.photo" style="width: 80px; height: 80px">
              <v-img :src="element.photo"></v-img>
            </div>
            <div v-else class="inline-middle talent_bg icon-user"></div>
          </v-avatar>
          <div class="inline-middle detail">
            <h3>{{ element.nominee }}</h3>
            <p>{{ element.job_title }}</p>
            <p>{{ element.agency }}</p>
            <p v-if="element.nominator">Nominated by {{ element.nominator }}</p>
          </div>
          <ul class="controls float-right">
            <li>
              <router-link
                :to="{
                  name: 'NominationForm',
                  query: { uuid: element.nomination_id },
                }"
                class="btn view icon-pencil"
              ></router-link>
            </li>
            <li>
              <v-btn
                class="btn icon-bin"
                size="x-small"
                @click="
                  deleteNominationDialog(
                    element.nomination_id,
                    index,
                    props.category
                  )
                "
              >
                <v-icon>icon-bin</v-icon>
              </v-btn>
            </li>
          </ul>
        </div>
        <div v-else class="blank unmover new_nomination_form_area">
          <div class="inline-middle talent_bg icon-user-blank"></div>
          <div class="inline-middle detail">
            <h3>Nominee</h3>
          </div>
          <ul class="controls float-right" v-if="props.canAdd">
            <li>
              <router-link
                :to="{
                  name: 'NominationForm',
                  query: { category: props.category },
                }"
              >
                <v-icon>mdi-plus</v-icon>
              </router-link>
            </li>
          </ul>
        </div>
      </template>
    </dragable>
  </div>
</template>

<script setup>
import { reactive, ref, watch, inject } from "vue";
const store = inject("store");
import dragable from "vuedraggable";
const props = defineProps(["modelValue", "canAdd", "category", "notRemove"]);
const emit = defineEmits(["addNomination", "openNominationDialog"]);
const nomination = reactive({ put: true, pull: true });
const nominationList = ref([]);
watch(
  () => props.modelValue,
  (newValue) => {
    nominationList.value = newValue;
  }
);
watch(() => props.notRemove,
  (newValue) => {
    if (newValue == true && props.category === "alternative") {
      nomination.pull = false;
    } else {
      nomination.pull = true;
    }
  })
function addNomination(e) {
  emit("addNomination", e);
}
function deleteNominationDialog(id, index, category) {
  emit("openNominationDialog", { id: id, index: index, category, category });
}
function onUpdate() {
  if (props.notRemove == true) {
    store.dispatch('showSnack', {text: 'The seats are full, can not be moved.', color: "error", timeout: 5000});
  }
}
</script>
