<template>
  <div class="people_page">
    <div class="people_tab">
      <ul>
        <li @click="num=0" :class="{active:num==0}">Key Roles</li>
        <li @click="num=1" :class="{active:num==1}">GLP Alumni</li>
        <li @click="num=2" :class="{active:num==2}">All Staff</li>
      </ul>
    </div>
    <div class="people_group">
      <div v-show="num==0" class="list"><component :is="'key_roles'"/></div>
      <div v-show="num==1" class="list"><component :is="'talent'"/></div>
      <div v-show="num==2" class="list"><component :is="'employees'"/></div>
    </div>
  </div>
</template>

<script>
import key_roles from "./key_roles";
import talent from "./talent";
import employees from "./employees";
export default {
  data() {
    return {
      num: 0,
    }
  },
  components: {
    key_roles,
    talent,
    employees
  },
}
</script>
