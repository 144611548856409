export const oidcSettings = {
  authority: process.env.VUE_APP_OAUTH2_ISSUER,
  clientId: process.env.VUE_APP_OAUTH2_CLIENTID,
  redirectUri: window.location.href.split('/')[0]+"//"+window.location.href.split('/')[2]+"/oidc-callback",
  responseType: 'id_token token',
  scope: 'openid profile email',
  post_logout_redirect_uri: window.location.href.split('/')[0]+"//"+window.location.href.split('/')[2],
  loadUserInfo: false,
  silent_redirect_uri: window.location.href.split('/')[0]+"//"+window.location.href.split('/')[2]+"/oidc-silent-renew",
  automaticSilentRenew: true,
  metadata: {
    issuer: process.env.VUE_APP_OAUTH2_ISSUER,
    authorization_endpoint: process.env.VUE_APP_OAUTH2_ISSUER+"/oauth2/authorize/",
    token_endpoint: process.env.VUE_APP_OAUTH2_ISSUER+"/oauth2/token/",
    jwks_uri: process.env.VUE_APP_OAUTH2_ISSUER+"/discovery/keys",
    signingKeys: [process.env.VUE_APP_OAUTH2_KID]
  }
}