<template>
  <div style="margin-top: 40px" class="financeMain">
    <h1 class="mt-2 mb-5">Finance</h1>
    <v-row>
      <v-col md="12" sm="12" style="margin-bottom: 0;">
        <v-card color='grey-darken-3' dark class="bag">
          <v-card-text class="h126 finance_list">
            <p><span>Operational reporting:</span> {{ data.op_reporting }}</p>
            <p><span>Operational status:</span> {{ data.op_status }}</p>
            <p><span>Ownership structure:</span> {{ data.ownership_structure }}</p>
            <p><span>Hyperion code:</span> {{ data.hyperion_code }}</p>
            <p><span>Location code:</span> {{ data.location_code }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
  async created(){
    const location_code = this.$route.params.location_code;
    this.$http.get('/api/v1/agencies/show_tab?tab_name=finance&location_code=' + location_code + '')
      .then(response => {
        this.data = response.data;
      })
  },
}
</script>
