<template>
  <transition name="fade">
    <v-btn
      icon="mdi-chevron-up"
      v-show="show"
      class="goTop"
      color="blue-darken-2"
      @click="GoTop"
    >
    </v-btn>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  created() {
  },
  methods: {
    handleScroll() {
      if (this.$root.$el.querySelector("#main").scrollTop > 500) {
        this.show = true
      } else {
        this.show = false
      }
    },
    GoTop(){
      this.$root.$el.querySelector("#main").scrollTo({top: 0, left:0, behavior: 'smooth'});
    }
  }
}
</script>

<style lang="scss" scoped>
.goTop {
  position: fixed;
  z-index: 5;
  right: 10px;
  bottom: 30px;
  margin-bottom: 50px;
  margin-right: 17px;
}
</style>
