<template>
  <div>
    <component :is="'agency_header'"/>
    <component :is="'contact'"/>
    <component :is="'finance'"/>
  </div>
</template>

<script>
import agency_header from "./agency_header";
import contact from "./contact";
import finance from "./finance";
export default {
  components: {
    agency_header,
    contact,
    finance
  },
}
</script>
