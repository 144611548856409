import axios from 'axios'
import router from '@/router'
import store from '@/store'

const errorHandle = (status) => {
  switch (status) {
  case 400:
    router.replace({
      path: '/',
      query: {
        redirect: router.currentRoute.value.fullPath
      }
    });
    break;
  case 401:
    router.replace({
      path: '/logout',
      query: {
        redirect: router.currentRoute.value.fullPath
      }
    });
    break;
  case 403:
    if (router.currentRoute.value.path.indexOf("nominations") > -1) {
      router.replace({
        path: '/nominations/forbidden',
      });
    } else if (router.currentRoute.value.path.indexOf("opportunities") > -1) {
      router.replace({ path: '/opportunities/forbidden' });
    } else {
      router.replace({
        path: '/403',
      });
    }
    break;
  case 404:
    router.replace({
      path: '/404',
    });
    break;
  default:
  }
}

const snackbarColor = ['success', 'error']

axios.defaults.baseURL = process.env.VUE_APP_COLLECTIVE_APIS_URL;

axios.interceptors.request.use(
  async config => {
    if (config.url.indexOf('noloading') < 0) {
      store.dispatch('SetLoading', true);
    }
    // for okta
    // config.headers['Authorization'] = `Bearer ${await store.getters['oidcStore/oidcAccessToken']}`;
    config.headers['Authorization'] = `Bearer ${await store.getters['oidcStore/oidcIdToken']}`;
    return config;
  },
  err => {
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  res => {
    if (res.config.url.indexOf('noloading') < 0) {
      store.dispatch('SetLoading', false);
    }
    if (res.data && snackbarColor.indexOf(res.data.status) > -1) {
      store.dispatch('showSnack', { text: res.data.message, color: res.data.status, timeout: 5000 });
    }
    return res.status === 200 ? Promise.resolve(res) : Promise.reject(res)
  },
  error => {
    const { response } = error;
    if (response) {
      store.dispatch('SetLoading', false);
      errorHandle(response.status);
      return Promise.reject(response);
    }
  })

export default axios
