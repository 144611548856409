<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName"/>
  </svg>
</template>

<style scoped>
  .svg-icon {
    display: inline-block;
    color: inherit;
    vertical-align: middle;
    fill: none;
    stroke: currentColor;
  }
  .svg-fill {
    fill: currentColor;
    stroke: none;
  }
</style>
<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconName () {
      return `#icon-${this.iconClass}`
    },
    svgClass () {
      if (this.className) {
        return 'svg-icon ' + ' ' + 'svg-fill' + this.className
      } else {
        return 'svg-icon' + ' ' + 'svg-fill'
      }
    },
  },
}
</script>