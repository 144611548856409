<template>
  <v-dialog v-model="show" persistent max-width="1000px">
    <v-card>
      <v-form
        ref="formRef"
        v-model="valid"
        :lazy-validation="false"
        class="pb-12 dialog_model"
      >
        <v-card-title>
          <span class="text-h5 text-capitalize"></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="status_area">
              <div class="field">
                <h2>From</h2>
                <div class="control">
                  <v-text-field
                    v-model="sendFrom"
                    required
                    variant="underlined"
                    :rules="[rules.required]"
                  ></v-text-field>
                </div>
              </div>
              <div class="field">
                <h2>To</h2>
                <div class="control">
                  <multi-employee-search
                    :selectedEmployee="sendTo"
                    userType="emailTo"
                    :placeHolder="'Please enter email'"
                    displayType="email"
                    @setValue="updateVal"
                    :rules="rules.array_required"
                  />
                </div>
              </div>
              <div class="field">
                <h2>Cc</h2>
                <div class="control">
                  <multi-employee-search
                    :selectedEmployee="sendCc"
                    userType="emailCc"
                    :placeHolder="'Please enter email'"
                    @setValue="updateVal"
                    displayType="email"
                    :rules="true"
                  />
                </div>
              </div>
              <div class="field">
                <h2>Subject</h2>
                <div class="control">
                  <v-text-field
                    v-model="subject"
                    :rules="[rules.required]"
                    variant="underlined"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="control">
              <v-switch
                v-model="preview"
                label="Preview"
                @update:modelValue="populatePreview"
              ></v-switch>
            </div>
            <div class="control control_box" v-if="preview === false">
              <div style="border: 1px solid #ccc">
                <toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editorRef"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
                />
                <editor
                  v-model="emailBody"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="handleCreated"
                  class="wangEditor"
                />
              </div>
            </div>
            <div class="ql-editor reminders_border" v-else>
              <div
                class="body-top"
                style="
                  height: 40px;
                  color: #ffffff;
                  text-align: left;
                  line-height: 40px;
                  background: #ffce00;
                  padding: 0 10px;
                "
              >
                <h1 style="height: 100%; margin: 0; float: left">
                  <span
                    style="
                      display: inline-block;
                      vertical-align: top;
                      height: 40px;
                      line-height: 40px;
                    "
                    >GLP Nomination</span
                  >
                </h1>
                <div class="controls" style="float: right"></div>
                <div style="clear: both"></div>
              </div>
              <div class="status_email_body" v-dompurify-html="emailBodyPreview"></div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="sendCeoNotification()"
            :disabled="!valid || emptyEmail"
            >SEND</v-btn
          >
          <v-btn color="blue-darken-1" variant="text" @click="show = false"
            >CANCEL</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import MultiEmployeeSearch from "@/components/common/multiEmployeeSearch";
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { i18nChangeLanguage } from "@wangeditor/editor";
import {
  onBeforeUnmount,
  ref,
  computed,
  shallowRef,
  onMounted,
  getCurrentInstance,
} from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
i18nChangeLanguage("en");
export default {
  components: { MultiEmployeeSearch, Editor, Toolbar },
  setup(props) {
    const editorRef = shallowRef();
    const emailBody = ref();
    const sendTo = ref();
    const subject = ref();
    const sendCc = ref();

    onMounted(() => {
      const instance = getCurrentInstance();
      const http = instance.appContext.config.globalProperties.$http;
      http.get("/api/v1/markets/nominee_info/", { params: { uuid: props.uuid } })
        .then((response) => {
          sendTo.value = response.data.email_to;
          emailBody.value = response.data.email_body;
          subject.value = response.data.email_subject;
          sendCc.value = response.data.email_cc;
        });
    });
    const editorConfig = {};
    const toolbarConfig = {
      toolbarKeys: [
        "header1",
        "bold",
        "italic",
        "|",
        "bulletedList",
        "insertLink",
      ],
    };

    onBeforeUnmount(() => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.destroy();
    });

    const handleCreated = (editor) => {
      editorRef.value = editor;
      // setTimeout(() => {
      //   const toolbar = DomEditor.getToolbar(editor);
      //   const curToolbarConfig = toolbar?.getConfig();
      // }, 1000);
    };

    const emptyEmail = computed(() => {
      return emailBody.value == '<p><br class="ProseMirror-trailingBreak"></p>';
    });

    return {
      editorRef,
      mode: "simple",
      emailBody,
      toolbarConfig,
      editorConfig,
      handleCreated,
      sendFrom: ref("no-reply-collective@tbwa.com"),
      valid: ref(false),
      preview: ref(false),
      emailBodyPreview: ref(null),
      sendTo,
      subject,
      sendCc,
      rules: {
        required: (value) => !!value || "This field is required.",
        array_required: (value) => value.length > 0 || "This field is required.",
      },
      emptyEmail,
      emailTo: ref([]),
      emailCc: ref([]),
    };
  },
  props: {
    value: Boolean,
    uuid: null,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    sendCeoNotification() {
      this.$http
        .post("/api/v1/notifications/send_ceo_notification", {
          email_body: this.emailBody,
          email_from: this.sendFrom,
          email_to: this.emailTo,
          email_cc: this.emailCc,
          email_subject: this.subject,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.show = false;
          }
        });
    },
    populatePreview() {
      if (this.preview) {
        this.$http
          .post("/api/v1/markets/nominee_info_preview", {
            email_body: this.emailBody,
            email_to: this.emailTo,
          })
          .then((response) => {
            this.emailBodyPreview = response.data.preview;
          });
      } else {
        this.emailBodyPreview = null;
      }
    },
    updateVal(data) {
      this[data.type] = data.val.map((item) => item?.email).join(",");
    },
  },
};
</script>
