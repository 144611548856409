<template>
  <div>
    Please wait for redirect to login page.
  </div>
</template>
<script>
export default {
  async created(){
    if (this.$route.path !== '/') this.$router.push({ path: '/' })
  }
}
</script>
