<template>
  <GoogleMap :api-key="apiKey" style="width: 100%; height: 500px" :center="center" :zoom="15" language='en' gestureHandling = "none">
    <GoogleMarker :options="markerOptions"/>
  </GoogleMap>
</template>
<script>
import { GoogleMap, Marker as GoogleMarker } from "vue3-google-map";
export default {
  components: {
    GoogleMap, GoogleMarker
  },
  setup(props) {
    const apiKey = process.env.VUE_APP_GOOGLE_API_KEY;
    const center = { lat: parseFloat(props.position.lat), lng: parseFloat(props.position.lng) };
    const markerOptions = { position: center, scrollwheel: false};
    return { apiKey, center, markerOptions};
  },
  props:{
    position: null,
  },
}
</script>