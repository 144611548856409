<template>
  <div>
  </div>
</template>
<script setup>
import { vuexOidcProcessSilentSignInCallback } from 'vuex-oidc'
import { oidcSettings } from '../config/oidc'

vuexOidcProcessSilentSignInCallback(oidcSettings)
</script>
