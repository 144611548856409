<template>
  <div class="preview-program-dialog-left">
    <div class="nomination_summary">
      <h2>{{ program.displayName }}</h2>
      <p>
        {{ program.description }}
      </p>
    </div>
    <div class="nomination_content row">
      <div class="v-col-12">
        <template v-if="isOmnicom">
          <div class="nominee_flex mb-4 pb-2">
            <i class="icon-checkmark"></i>
            <div class="pl-1" style="width: 100%">
              <h3>Core Content</h3>
              <template v-if="program.coreContentVideoUrl === ''">
                <img
                  :src="program.coreContentUrl"
                  style="width: 100%"
                  class="img_thumbnail"
                />
              </template>
              <template v-else>
                <video controls style="width: 100%">
                  <source :src="program.coreContentVideoUrl" :type="program.core_content_video_content_type" />
                  <p>
                    Your browser doesn't support HTML video. Here is a
                    <a href="myVideo.mp4">link to the video</a> instead.
                  </p>
                </video>
              </template>
            </div>
          </div>
          <div class="nominee_flex mb-4 pb-2">
            <i class="icon-checkmark"></i>
            <div class="pl-1">
              <h3>Learner Stage: {{ program.learner_stage_title }}</h3>
              <p>
                {{ program.learner_stage }}
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="nominee_flex mb-4 pb-2">
            <i class="icon-checkmark"></i>
            <div class="pl-1">
              <h3>Learner Stage: {{ program.learner_stage_title }}</h3>
              <p>
                {{ program.learner_stage }}
              </p>
            </div>
          </div>
          <div class="nominee_flex mb-4 pb-2">
            <i class="icon-checkmark"></i>
            <div class="pl-1" style="width: 100%">
              <h3>Core Content</h3>
              <img
                :src="program.coreContentUrl"
                style="width: 100%"
                class="img_thumbnail"
              />
            </div>
          </div>
        </template>
        <div class="nominee_flex mb-4 pb-2 nominee_box">
          <i class="icon-checkmark"></i>
          <div class="pl-1">
            <h3>Program Duration / Dates</h3>
            <div class="Program_text">
              <div class="w120">
                <span class="nominee_title">Program Dates :</span>
              </div>
              <p class="w120_right">
                {{ program.virtual_edition }}
              </p>
            </div>
            <div class="clear"></div>
          </div>
        </div>
        <div class="nominee_flex mb-4 pb-2">
          <i class="icon-checkmark"></i>
          <div class="pl-1">
            <h3>Investment Cost Per Participant</h3>
            <p>{{ program.cost }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "~@/assets/styles/app/nominations.scss";
</style>
<script>
export default {
  props: {
    program: {},
  },
  computed: {
    isOmnicom: function(){
      return this.program.displayName && this.program.displayName.toLowerCase().indexOf('omnicom') == -1
    }
  }
};
</script>
