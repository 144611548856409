<template>
  <div class="mainBody result">
    <v-expansion-panels multiple v-model="panel">
      <!-- Agencies -->
      <v-expansion-panel :disabled="agencies.length == 0" height="400">
        <v-expansion-panel-title>
          Agencies<v-chip variant="elevated" size="small" class="ma-2" :color="agencies.length > 0 ? 'blue-darken-2':'grey'" style="flex: inherit;">{{ agencies.length }}</v-chip>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <Agencies :data="agencies" :search_id="search_id" v-if="resetPage" />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- Employees -->
      <v-expansion-panel :disabled="employees.length == 0">
        <v-expansion-panel-title>
          People<v-chip variant="elevated" size="small" class="ma-2" :color="employees.length  > 0 ? 'blue-darken-2':'grey'" style="flex: inherit;">{{ employees.length }}</v-chip>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <Employees :data="employees" :search_id="search_id" :keywords="keywords" v-if="resetPage" />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- Collections -->
      <v-expansion-panel :disabled="collections.length == 0">
        <v-expansion-panel-title>
          Collections<v-chip variant="elevated" size="small" class="ma-2" :color="collections.length  > 0 ? 'blue-darken-2':'grey'" style="flex: inherit;">{{ collections.length }}</v-chip>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <Collections :data="collections" :search_id="search_id" v-if="resetPage" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<style lang="scss">
  @import '~@/assets/styles/app/search.scss';
</style>
<script>
import Agencies from './agencies';
import Employees from './employees';
import Collections from './collections';
export default {
  data() {
    return {
      agencies: [],
      employees: [],
      collections: [],
      search_id: null,
      panel: [],
      keywords: null,
      resetPage: null,
    }
  },
  components: {
    Agencies,
    Employees,
    Collections
  },
  async created(){
    if (this.searchVal){
      this.search(this.searchVal);
    }
  },
  props: {
    searchVal: null,
  },
  watch: {
    searchVal: function (val) {
      this.search(val);
    }
  },
  methods: {
    search: function(keywords){
      if (keywords == '' || keywords == null) return false;
      this.keywords = keywords;
      this.resetPage = false;
      this.agencies = [];
      this.employees = [];
      this.collections = [];
      this.$http.get('/api/v1/search/global_search.json', {params: {keywords: this.searchVal}})
        .then(response => {
          this.panel = [];
          this.agencies = response.data.agencies;
          this.employees = response.data.employees;
          this.search_id = response.data.search_id;
          this.collections = response.data.collections;
          this.resetPage = true;
          if (this.agencies.length > 0) {
            this.panel.push(0)
          }
          if (this.employees.length > 0) {
            this.panel.push(1)
          }
          if (this.collections.length > 0) {
            this.panel.push(2)
          }
        })
    }
  }
}

</script>
