const router = [{
  path: '/leaders',
  component: () => import('./index'),
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: 'settings',
      name: 'Settings',
      component: () => import('./settings'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'historical_search',
      name: 'HistoricalSearch',
      component: () => import('./historicalSearch'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'guideline',
      name: 'GuideLine',
      component: () => import('./guideLine'),
      meta: {
        requiresAuth: true
      },
    }
  ]
}]

export default router
