<template>
  <div>
    <v-btn
      size="small"
      color="#ffce00"
      class="float-right my-3"
      @click="showAll()"
      >Show All</v-btn
    >
    <div class="float-left">
      <v-text-field
        v-model="search"
        append-inner-icon="mdi-filter"
        label="Filter"
        single-line
        style="width: 360px"
        @update:modelValue="page = 1"
        variant="underlined"
        density="compact"
        hide-details
      ></v-text-field>
    </div>
    <v-data-table
      :headers="headers"
      :items="data"
      v-model:page="page"
      :items-per-page="itemsPerPage"
      :disable-pagination="!showPage"
      hide-default-footer
      fixed-header
      class="search_table"
      :search="search"
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.name`]="{ item }">
        <div class="detail">
          <div class="inline-middle agency_bg icon-office mr-2">
            <div></div>
          </div>
          <div class="inline-middle detail mt-1" @click="recordSearch(item)">
            <router-link
              :to="{
                name: 'Agency',
                params: { location_code: item.selectable.location_code },
              }"
            >
              {{ item.selectable.name }}
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:bottom>
        <div class="text-center pagination pt-2" v-if="showPage">
          <v-pagination
            v-model="page"
            :length="pageCount"
            total-visible="7"
            density="comfortable"
            active-color="blue-darken-2"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      itemsPerPage: 6,
      headers: [
        { title: "Name", key: "name" },
        { title: "Country", key: "country" },
        { title: "City", key: "city" },
      ],
      search: null,
      showPage: true,
    };
  },
  props: {
    data: null,
    search_id: null,
  },
  methods: {
    recordSearch: function (item) {
      this.$http.post(
        "/api/v1/search/record_global_search?noloading=true&selected_type=agencies&search_id=" +
          this.search_id +
          "&selected_id=" +
          item.selectable.location_code +
          "&selected_name=" +
          item.selectable.name
      );
    },
    showAll() {
      this.showPage = false;
      this.itemsPerPage = -1;
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.data.length / this.itemsPerPage);
    },
  },
};
</script>
