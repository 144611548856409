const files = require.context('./tabs', true, /\.vue$/);
let pages = {};
files.keys().forEach(key => {
  pages[key.replace(/(\.\/|\.vue)/g, '')] = files(key).default;
})

let tabs = [];

Object.keys(pages).forEach(item => {
  tabs.push({
    path: `${item}`,
    name: item,
    component: pages[item]
  })
});

const router = [
  {
    path: '/agencies',
    name: 'AgencyList',
    component: () => import('./index'),
    redirect: { name: 'AgencyMain' },
    children: [
      {
        path: 'main',
        name: 'AgencyMain',
        component: () => import('./index_main'),
      },
      {
        path: ':location_code',
        name: 'Agency',
        component: () => import('./show'),
        children: [
          ...tabs
        ]
      }
    ]
  },
]
export default router
