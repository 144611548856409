const router = [
  {
    path: '/my_dashboard',
    name: 'MyDashboard',
    component: () => import('./index'),
    redirect: '/my_dashboard/main',
    children: [
      {
        path: 'main',
        name: 'MyDashboardMain',
        component: () => import('./main'),
      },
    ]
  },
]
export default router
