<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="data"
      v-model:page="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="search_table"
      fixed-header
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.name`]="{item}">
        <div class="detail">
          <div class="inline-middle agency_bg icon-user mr-2">
            <div></div>
          </div>
          <div class="inline-middle detail mt-1" @click="recordSearch(item)">
            <router-link
              :to="{
                name: 'Collection',
                params: { uuid: item.selectable.uuid },
              }"
            >
              {{ item.selectable.name }}
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:bottom>
        <div class="text-center pagination pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            total-visible="7"
            density="comfortable"
            active-color="blue-darken-2"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      itemsPerPage: 6,
      headers: [
        { title: 'Name', key: 'name' },
        { title: 'Owner', key: 'owner' },
        { title: 'Type', key: 'type' },
        { title: 'Visibility', key: 'visibility' },
      ],
    }
  },
  props: {
    data: null,
    search_id: null,
  },
  methods: {
    recordSearch: function (item) {
      this.$http.post(
        '/api/v1/search/record_global_search?noloading=true&selected_type=collections&search_id=' +
          this.search_id +
          '&selected_id=' +
          item.selectable.uuid +
          '&selected_name=' +
          item.selectable.name
      )
    },
  },
  computed: {
    pageCount () {
      return Math.ceil(this.data.length / this.itemsPerPage)
    },
  },
}
</script>
