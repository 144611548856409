<template>
  <div
    :class="{
      requiredClass: classStyle.isRequired,
      focusClass: classStyle.isFocus,
      hasValueClass: classStyle.isHasValue,
      hasShakeClass: classStyle.isShake,
      hideStarClass: classStyle.isHideStar,
      disabledClass: disabled
    }"
  >
    <span class="new-calendar-label">{{ calendarLabel }}<span>*</span></span>
    <VueDatePicker
      v-model="inputDate"
      :enable-time-picker="false"
      :format="formatDate"
      :month-change-on-scroll="false"
      :action-row="{ showCancel: false, showSelect: false }"
      :text-input-options="textInputOptions"
      :text-input="canText"
      auto-apply
      :disabled="disabled"
      :placeholder="calendarLabel"
      @blur="checkRequired"
      @closed="closed"
      @open="focusCalendar"
      @mousedown="mousedownCalendar"
    >
    </VueDatePicker>
    <span class="new-calendar-caption">*</span>
    <span class="new-calendar-model"></span>
    <span class="new-calendar-required">Date is required</span>
  </div>
</template>
<script>
import { computed, ref, reactive } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  components: { VueDatePicker },
  setup(props, ctx) {
    const textInputOptions = ref();

    const classStyle = reactive({
      isRequired: ref(false),
      isFocus: ref(false),
      isHasValue: ref(false),
      isShake: ref(false),
      isHideStar: ref(false),
    });

    if (props.dataFormat) {
      textInputOptions.value = { format: props.dataFormat };
    } else {
      textInputOptions.value = { format: "yyyy-MM-dd" };
    }

    function formatDate(date) {
      if (!date) return;
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      if (props.dataFormat) {
        return `${day}/${month}/${year}`;
      } else {
        return `${year}-${month}-${day}`;
      }
    }
    init();
    function init(){
      if(props.value && props.value != ""){
        classStyle.isHasValue = true;
      }
    }

    function closed() {
      classStyle.isShake = false;
      classStyle.isHideStar = false;
    }

    const inputDate = computed({
      get: () => {
        return props.value;
      },
      set: (value) => {
        if (value && value != "") {
          classStyle.isHasValue = true;
          classStyle.isRequired = false;
        } else {
          classStyle.isHasValue = false;
          classStyle.isRequired = true;
          classStyle.isFocus = false;
          classStyle.isShake = true;
        }
        if (props.dataFormat) {
          ctx.emit("update:value", value);
        } else {
          ctx.emit("update:value", formatDate(value));
        }
      },
    });

    const calendarLabel = computed(() => {
      if (props.required && props.label) {
        return props.label;
      } else {
        return props.label;
      }
    });

    const canText = computed(() => {
      return props.isText === false ? props.isText : true
    })

    const checkRequired = () => {
      classStyle.isHideStar = false;
      classStyle.isShake = false;
      classStyle.isFocus = false;
      if (props.required) {
        if (!inputDate.value || inputDate.value == "NaN-NaN-NaN") {
          classStyle.isRequired = true;
        } else {
          classStyle.isRequired = false;
        }
      } else {
        classStyle.isRequired = false;
      }
    };

    const focusCalendar = () => {
      classStyle.isFocus = true;
    };

    const mousedownCalendar = () => {
      classStyle.isHideStar = true;
    };

    return {
      textInputOptions,
      formatDate,
      inputDate,
      checkRequired,
      focusCalendar,
      closed,
      classStyle,
      calendarLabel,
      mousedownCalendar,
      canText
    };
  },
  props: {
    value: null,
    required: Boolean,
    label: null,
    disabled: Boolean,
    dataFormat: null,
    isText: Boolean
  },
};
</script>
