<template>
  <div style="margin-top: 40px">
    <h1 class="mt-2 mb-5">Contact</h1>
    <v-row align="start"  class="contact_info">
      <div class="contact_main" v-show="data.url || data.phone || data.address || data.facebook || data.linkedin || data.twitter || data.hr_link_email || data.public_email" :class="data.gmap && data.gmap.lng ? '' : 'contact_position'">
        <div v-show="data.url || data.phone || data.address">
          <p v-show="data.url"><span>Web: </span><a @click="$openWindow(data.url)">{{data.url}}</a></p>
          <p v-show="data.phone"><span>Phone: </span>{{data.phone}}</p>
          <p v-show="data.address"><span>Address: </span>{{data.address}}</p>
        </div>
        <div>
            <p v-show="data.facebook"><span>Facebook: </span><a @click="$openWindow(data.facebook)">{{data.facebook}}</a></p>
            <p v-show="data.linkedin"><span>LinkedIn: </span><a @click="$openWindow(data.linkedin)">{{data.linkedin}}</a></p>
            <p v-show="data.twitter"><span>Twitter: </span><a @click="$openWindow(data.twitter)">{{data.twitter}}</a></p>
        </div>
        <div v-show="data.hr_link_email || data.public_email">
            <p><span>Email: </span>{{data.hr_link_email}}<br>{{data.public_email}}</p>
        </div>
      </div>
      <div class="v-col-12 contact_map">
        <Gmap :position="data.gmap" v-if="data.gmap && data.gmap.lng"/>
      </div>
    </v-row>
  </div>
</template>
<script>
import Gmap from '@/components/common/gmap'
export default {
  components: { Gmap },
  data() {
    return {
      data: {gamp: {lng: null, lat: null}}
    }
  },
  async created(){
    const location_code = this.$route.params.location_code;
    this.$http.get('/api/v1/agencies/show_tab?tab_name=contact&location_code=' + location_code + '')
      .then(response => {
        this.data = response.data;
      })
  },
}
</script>
