<template>
  <div>
    <div class="nomination_opt float-right" v-if="!is_submitted && can_submit && is_market_leader">
      <v-btn @click="submitOutConfirm()">Opt-Out</v-btn>
    </div>
    <section
      class="nomination_form footer float-right"
      v-if="!is_submitted && can_submit"
    >
      <div class="float-right">
        <ul class="controls">
          <li style="margin: 0 3px">
            <v-btn
              :disabled="is_submitted"
              rounded
              color="green"
              @click="submitConfirm()"
              style="text-transform: capitalize"
              >I am done adding nominees
            </v-btn>
          </li>
        </ul>
        <div class="clear"></div>
      </div>
    </section>
    <deleteDialog
      v-model:value="submit_confirm_dialog"
      @agree="submitNominees"
      :message="show_message()"
      title="Submit"
    />
    <v-dialog v-model="openSubmitDialog" persistent max-width="600px">
      <v-card class="business-dialog">
        <v-card-title>
          Opt-Out
        </v-card-title>
        <v-card-text>
          <div class="business-text">
            <v-checkbox
              v-for="(program, i) in opt_out_programs"
              :key="i"
              v-model="selected_opt_out_programs"
              :label="program.name"
              :value="program.uuid"
              color="#1976d2"
            ></v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions class="pa-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="submitOut">YES</v-btn>
          <v-btn color="blue-darken-1" variant="text" @click.stop="openSubmitDialog=false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import deleteDialog from "@/components/common/deleteDialog";
import { mapGetters } from 'vuex';
export default {
  emits: ['refreshData'],
  components: { deleteDialog },
  data() {
    return {
      submit_confirm_dialog: false,
      openSubmitDialog: false,
      opt_out_programs: [],
      selected_opt_out_programs: [],
    };
  },
  props: {
    is_submitted: Boolean,
    can_submit: Boolean,
    is_market_leader: Boolean,
    show_no_nominee_text: Boolean,
    nominations: Array,
    is_ww_leader: Boolean,
    market_id: null,
  },
  computed: {
    ...mapGetters(['permissions']),
  },
  methods: {
    submitConfirm() {
      this.submit_confirm_dialog = true;
    },
    submitOutConfirm() {
      this.openSubmitDialog = true;
      this.$http
        .get(
          "/api/v1/nominations/get_opt_out_programs"
        )
        .then((response) => {
          this.opt_out_programs = response.data;
          this.selected_opt_out_programs = [this.$route.params.id];
        });
    },
    submitNominees() {
      this.submit_confirm_dialog = false;
      this.$parent.submitNominees();
    },
    submitOut() {
      this.$http
        .post("/api/v1/nominations/submit_opt_out", {
          program_uuid: this.selected_opt_out_programs,
          market_id: this.market_id,
        })
        .then(() => {
          this.openSubmitDialog = false;
          this.$emit('refreshData', true);
        });
    },
    show_message(){
      if(this.permissions.is_super_admin || this.permissions.is_glp_worldwide_leader){
        return "Are you sure to approve this program?";
      }else{
        return "Are you sure to submit this program?";
      }
    },
  }
}
</script>
