import * as echarts from "echarts/core";
import { BarChart, PieChart,LineChart,RadarChart,ScatterChart,PictorialBarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  // GeoCoComponent
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  PieChart,
  LineChart,
  RadarChart,
  ScatterChart,
  PictorialBarChart,

  CanvasRenderer

]);

export default echarts;
