import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from './plugins/vuetify'
import axios from '@/utils/request'
import store from './store'
import breadcrumbs from '@/components/common/breadcrumbs'
import calendar from '@/components/common/calendar'
import vue3JsonExcel from 'vue3-json-excel'
import { Calendar } from 'v-calendar';
import VueDOMPurifyHTML from 'vue-dompurify-html'
import 'v-calendar/style.css';
import '@/SvgIcon/index.js'
import SvgIcon from '@/SvgIcon/index.vue'

const app = createApp(App);


app.config.globalProperties.$getRedirectUrl = function(url) {
  return window.location.href = url;
};

app.config.globalProperties.$openWindow = function(url) {
  return window.open(url, '_blank');
};

app.config.globalProperties.$http = axios;
app
  .component('breadcrumbs', breadcrumbs)
  .component('calendar', calendar)
  .component('svg-icon', SvgIcon)
  .component('VCalendar', Calendar)
  .use(vue3JsonExcel)
  .use(Vuetify)
  .use(store)
  .use(router)
  .use(VueDOMPurifyHTML)
  .mount('#app')
app.config.globalProperties.routerAppend = (path, pathToAppend) => {
  return path + (path.endsWith('/') ? '' : '/') + pathToAppend
}
