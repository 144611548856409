<template>
  <div class="nominations_main">
    <div class="content_text float-left">
      <Program :program="program" />
    </div>
    <router-view :program="program" :tabInfo="$attrs.tabInfo" v-bind="$attrs"></router-view>
    <div class="clear"></div>
  </div>
</template>
<style lang="scss" scoped>
@import "~@/assets/styles/app/nominations.scss";
</style>
<script>
import Program from "./program";
export default {
  data() {
    return {
      nominationData: { nominations: [] },
      showList: true,
    };
  },
  components: {
    Program,
  },
  props: {
    program: null,
  },
};
</script>
