<template>
  <v-dialog v-model="show" persistent max-width="540px" class="nominationsBody editNominee">
    <v-card class="px-6 py-2">
      <section class="nomination_form form w-100">
        <v-container class="form-body">
          <div class="field">
            <span class="inline-top label_title"><span class="required_start"> *</span> Nominee</span>
            <div class="inline-top control">
              <div class="nominee-info">
                <v-avatar start size="50" class="float-left avatar">
                  <v-img :src="item.photo"></v-img>
                </v-avatar>
                <div class="float-left text ml-5">
                  <h2>{{ item.nominee }}</h2>
                  <h4>({{ item.nominee_email }})</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <span class="inline-top label_title"><span class="required_start"> *</span> Market</span>
            <div class="inline-top control full-width">
              <v-text-field
                v-model="item.market"
                placeholder="Market Name"
                variant="outlined"
                density="compact"
                class="v-text-filed-compact"
                disabled
                required
              ></v-text-field>
            </div>
          </div>
          <div class="field">
            <span class="inline-top label_title"><span class="required_start"> *</span> Agency</span>
            <div class="inline-top control">
              <v-text-field
                v-model="item.agency"
                placeholder="Agency Name"
                variant="outlined"
                density="compact"
                class="v-text-filed-compact"
                disabled
                required
              ></v-text-field>
            </div>
          </div>
          <div class="field">
            <span class="inline-top label_title"><span class="required_start"> *</span> Programs</span>
            <div class="control v-row">
              <v-select
                v-model="program"
                :items="programs"
                variant="underlined"
                item-title="name"
                item-value="id"
                density="compact"
                class="field_select v-col-8 "
              ></v-select>
              <v-select
                v-model="season"
                :items="seasons"
                variant="underlined"
                item-title="name"
                item-value="id"
                density="compact"
                class="field_select v-col-4 pl-4"
              ></v-select>
            </div>
          </div>
          <div class="btn mt-8">
            <v-btn color="#fecc00" @click="changeProgram">SUBMIT</v-btn>
            <v-btn color="#e0e0e0" @click="show = false">CANCEL</v-btn>
          </div>
        </v-container>
      </section>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
@import "~@/assets/styles/app/nominations.scss";
</style>
<script>
export default {
  data() {
    return {
      editNomineeDialog: false,
      program: null,
      programs: [],
      season: 'current',
      seasons: [{ name: 'Current Season', id: 'current' }, { name: 'Alternatives', id: 'alternative' }, { name: 'Next Season', id: 'next' }],
    };
  },
  props: {
    value: Boolean,
    item: null,
  },
  watch: {
    item: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getData();
          this.season = this.item.category;
        }
      },
    },
  },
  computed: {
    show: {
      get (){
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    },
  },
  methods: {
    getData() {
      this.$http
        .get("/api/v1/markets/dashboard", { params: { market: this.item.market } })
        .then((response) => {
          this.programs = response.data.programs;
          if (this.programs.length) this.program = this.item.program_id;
        });
    },
    changeProgram() {
      if (this.program != this.item.program_id || this.season != this.item.category) {
        this.$http
          .post("/api/v1/nominations/change_program", {
            program_id: this.program,
            category: this.season,
            uuid: this.item.nomination_id,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.$emit("updateValue", { user: this.item, category: this.seasons.filter(i => { return i.id == this.season })[0].name, program: this.program });
              this.show = false;
            }
          });
      }
    },
  },
};
</script>