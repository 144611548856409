<template>
  <div class="dashboard_main">
    <template v-if="programs.length > 0 && programs">
      <div class="dashboard_header">
        <div class="flex justify-space-between align-center">
          <div class="dashboard_input flex">
            <v-select
              v-model="program"
              :items="programs"
              variant="underlined"
              item-title="name"
              item-value="id"
              class="dashboard_select mr-10"
            ></v-select>
            <v-text-field
              v-model="searchText"
              append-inner-icon="mdi-menu"
              label="Filters"
              single-line
              variant="underlined"
              class="dashboard_filter"
            />
          </div>
          <div class="dashboard_btn flex align-center">
            <div class="dashboard_title flex align-center">
              <v-chip color="#6D6D6D" variant="outlined"
                >{{ programInfo.currency_symbol }}
                {{ programInfo.participant_cost }}/participant</v-chip
              >
              <v-chip color="#6D6D6D" variant="outlined"
                >Total: {{ programInfo.currency_symbol }}
                {{ currentNomineeLength * programInfo.participant_cost }}({{
                  currentNomineeLength
                }}
                {{ show_participants }})</v-chip
              >
            </div>
            <v-btn
              color="#67ac5b"
              v-if="
                !isApproved &&
                permissions.is_glp_worldwide_leader &&
                currentProgram.can_add
              "
              @click="submitConfirmDialog = true"
              >Finalize The Program</v-btn
            >
            <v-btn
              color="#ffce00"
              @click="dialog = true"
              v-if="currentProgram.can_add"
              >Add Nominee</v-btn
            >
          </div>
        </div>
      </div>
      <div class="nominees_box">
        <div class="nominees_list">
          <div class="v-row ma-0">
            <div
              class="nominees_item v-col-4 pa-0"
              v-for="(group, i) in nomineesList"
              :key="i"
            >
              <div class="nominees_title">
                <h3>{{ group.title }}</h3>
              </div>
              <draggable
                class="nominees_group pt-4"
                :list="group.item"
                group="people"
                item-key="group.title"
                @add="updateCategory($event, i)"
                ghostClass="ghost"
                chosenClass="chosen"
                :move="onMove"
                @end="onEnd"
              >
                <template #item="{ element }">
                  <div class="nominees_group_item ma-4 mt-0">
                    <v-avatar start size="80" class="avatar">
                      <v-img :src="element.photo" />
                    </v-avatar>
                    <div class="inline-middle detail ml-4">
                      <h3 class="flex">
                        <span :title="element.nominee">{{
                          element.nominee
                        }}</span>
                        <span>{{ element.market }}</span>
                      </h3>
                      <p>{{ element.job_title }}</p>
                      <p>{{ element.agency }}</p>
                      <p v-if="element.nominator">
                        Nominated by {{ element.nominator }}
                      </p>
                    </div>
                    <ul class="controls float-right">
                      <div v-if="group.title != 'Next Season' && element.earliest_next_season_program_id != null && program != element.earliest_next_season_program_id ">
                        <svg-icon icon-class="history"></svg-icon>
                        <v-tooltip
                          activator="parent"
                        >Originally nominated: {{ element.earliest_next_season_program_name }}
                        </v-tooltip>
                      </div>
                      <li>
                        <v-btn
                          class="btn icon-pencil"
                          @click="editNominee(element)"
                        />
                      </li>
                      <li>
                        <v-btn
                          class="btn icon-bin"
                          @click="deleteNominationDialog(element.nomination_id)"
                        >
                        </v-btn>
                      </li>
                    </ul>
                  </div>
                </template>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="no_data flex justify-center align-center">
        <div class="text-center">
          <img :src="require('@/assets/images/no_docu.png')" />
          <p>There is no submitted program.</p>
        </div>
      </div>
    </template>
    <deleteDialog
      v-model:value="submitConfirmDialog"
      @agree="submitNominees"
      message="Are you sure to approve this program?"
      title="Submit"
    />
    <edit-nominee-dialog
      v-model:value="editNomineeDialog"
      :item="editItem"
      v-if="editItem"
      @updateValue="updateItem"
    />
    <v-dialog v-model="dialog" persistent max-width="800px" scrollable>
      <v-card class="dashboard-nominee-dialog nominationsBody">
        <v-icon color="#000" @click="dialog = false">mdi-close</v-icon>
        <nomineeForm
          :dashboardAddNominee="dashboardAddNominee"
          @updateList="createItem"
          :program="program"
          :programs="programs"
          @closeForm="dialog = false"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" persistent max-width="506px">
      <v-card>
        <v-card-title>
          <span class="text-h5 text-capitalize">Remove</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            Are you sure you want to remove this employee from this nomination?
          </v-container>
        </v-card-text>
        <v-card-actions class="pa-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="deleteNominee()"
            >YES</v-btn
          >
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="deleteDialog = false"
            >CANCEL</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss" scoped>
@import "~@/assets/styles/app/nominations.scss";
.dashboard-nominee-dialog {
  position: relative;
  > .v-icon {
    position: fixed;
    top: 2px;
    right: 3px;
    z-index: 2;
  }
  .content-form {
    width: 100%;
  }
}
.v-tooltip {
  :deep(.v-overlay__content) {
    background: rgba(66,66,66,1)!important;
  }
}
</style>
<script>
import draggable from "vuedraggable";
import deleteDialog from "@/components/common/deleteDialog";
import { mapGetters } from "vuex";
import EditNomineeDialog from "./EditNomineeDialog.vue";
import nomineeForm from "@/components/nominations/show/form";
export default {
  components: {
    draggable,
    deleteDialog,
    EditNomineeDialog,
    nomineeForm,
  },
  data() {
    return {
      searchText: "",
      programs: [],
      program: null,
      isApproved: false,
      submitConfirmDialog: false,
      programInfo: {},
      editNomineeDialog: false,
      dialog: false,
      deleteDialog: false,
      deleteItem: null,
      editItem: null,
      dashboardAddNominee: true,
      orgNomineesList: [],
      el: null,
      elTarget: null,
      categoryList: {
        "Current Season": "current",
        Alternatives: "alternative",
        "Next Season": "next",
      }
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters(["permissions"]),
    currentProgram() {
      return (
        this.programs.filter((i) => {
          return i.id == this.program;
        })[0] || {}
      );
    },
    currentNomineeLength() {
      let currentNominee = this.orgNomineesList.filter((i) => {
        return i.title == "Current Season";
      });
      return currentNominee.length && currentNominee[0].item
        ? currentNominee[0].item.length
        : 0;
    },
    show_participants() {
      return this.currentNomineeLength <= 1 ? "participant" : "participants";
    },
    nomineesList() {
      if (this.searchText.length > 0) {
        let filterResult = [];
        let array = [];
        this.orgNomineesList.forEach((nominees) => {
          array = nominees.item.filter((item) => {
            return (
              item.nominee
                ?.toLowerCase()
                .includes(this.searchText.toLowerCase()) ||
              item.nominee_email
                ?.toLowerCase()
                .includes(this.searchText.toLowerCase()) ||
              item.market?.toLowerCase().includes(this.searchText.toLowerCase())
            );
          });
          filterResult.push({ title: nominees.title, item: array });
        });
        return filterResult;
      } else {
        return this.orgNomineesList;
      }
    },
  },
  watch: {
    program: function () {
      this.getNominations();
    },
  },
  methods: {
    getData() {
      this.$http.get("/api/v1/nominations/dashboard").then((response) => {
        this.programs = response.data.programs;
        if (this.programs.length) this.program = this.programs[0].id;
      });
    },
    getNominations() {
      this.$http
        .get("/api/v1/nominations/program_dashboard", {
          params: { program_id: this.program },
        })
        .then((response) => {
          this.orgNomineesList = response.data.nominations;
          this.isApproved = response.data.is_approved;
          this.programInfo = response.data.program;
          this.searchText = "";
        });
    },
    submitNominees() {
      this.submitConfirmDialog = false;
      this.$http
        .post("/api/v1/nominations/submit", {
          program_id: this.program,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.isApproved = true;
            this.getNominations();
          }
        });
    },
    editNominee(item) {
      this.editNomineeDialog = true;
      this.editItem = JSON.parse(JSON.stringify(item));
    },
    updateCategory(e, i) {
      let item = this.nomineesList[i].item[e.newIndex];
      this.$http
        .post("/api/v1/nominations/update_category", {
          uuid: item.nomination_id,
          category: this.categoryList[this.nomineesList[i].title],
          from: "dashboard",
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.removeNominee(item.nomination_id);
            this.createItem({
              user: item,
              category: this.nomineesList[i].title,
            });
          }
        });
    },
    deleteNominationDialog(id) {
      this.deleteDialog = true;
      this.deleteItem = id;
    },
    deleteNominee() {
      this.deleteDialog = false;
      this.$http
        .delete("/api/v1/nominations/" + this.deleteItem)
        .then((response) => {
          if (response.data.status == "success") {
            this.removeNominee(this.deleteItem);
          }
        });
    },
    removeNominee(id) {
      this.orgNomineesList.forEach((x, index) => {
        if (x.item) {
          x.item.forEach((nomination) => {
            if (nomination.nomination_id == id) {
              const itemIndex = x.item.indexOf(nomination);
              if (itemIndex >= 0)
                this.orgNomineesList[index].item.splice(itemIndex, 1);
            }
          });
        }
      });
    },
    updateItem(val) {
      this.removeNominee(val.user.nomination_id);
      if (val.program == this.program) {
        this.createItem(val);
      }
    },
    createItem(val) {
      val.user.category = this.categoryList[val.category]
      this.orgNomineesList.forEach((x, index) => {
        if (x.title == val.category) {
          this.orgNomineesList[index].item.unshift(val.user);
        }
      });
    },
    onMove(e) {
      if (this.elTarget != e.relatedContext.component) {
        if (this.el) {
          this.el.classList.remove("drag_list_success");
        }
        this.elTarget = e.relatedContext.component;
      }
      this.el = e.relatedContext.component.$el;
      this.el.classList.add("drag_list_success");
    },
    onEnd() {
      if (this.el) this.el.classList.remove("drag_list_success");
    },
  },
};
</script>
