<template>
  <div>
    <div class="mt-2 mb-5">
      <h1 class="float-left">Leadership</h1>
      <div class="clear"></div>
    </div>
    <div class="pitch_team_member_container flex justify-start">
      <div v-for="(user, index) in data" :key="index" class="members text-center v-col-3">
        <router-link :to="{ name: 'Employee', params: { uid: user.uid }}">
          <div v-if="user.profile_pic_url">
            <img :src="user.profile_pic_url+ '&width=120'"/>
          </div>
          <div v-else class="icon-user"></div>
          <h3>{{ user.full_name }}</h3>
          <p>{{ user.title }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
  async created(){
    const location_code = this.$route.params.location_code;
    this.$http.get('/api/v1/agencies/show_tab?tab_name=leadership&location_code=' + location_code + '')
      .then(response => {
        this.data = response.data;
      })
  },
}
</script>
