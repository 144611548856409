<template>
  <section class="agency-header">
    <v-img :src="init_image" class="header-bg">
      <div class="detail mb-4">
        <div class="field" v-if="data['is_admin']">
          <v-file-input
            v-model="headers"
            @change="fileInput"
            prepend-icon="mdi-camera-outline"
            class="float-right"
          >
          </v-file-input>
        </div>
        <h3 class="mr-4">{{ data["name"] }}</h3>
        <p>{{ region }}</p>
      </div>
    </v-img>
  </section>
</template>
<script>
export default {
  data() {
    return {
      default_url: require('@/assets/images/default_bg.png'),
      headers: [],
      header_url: null,
      data: {}
    }
  },
  async created(){
    const location_code = this.$route.params.location_code;
    this.$http.get('/api/v1/agencies/show_tab?tab_name=agency_header&location_code=' + location_code + '')
      .then(response => {
        this.data = response.data;
      })
  },
  computed: {
    region(){
      let title = '';
      if (this.data["city"] && this.data["region"]){
        title = this.data["city"] + ', ' + this.data["region"];
      }else if(!this.data["region"]){
        title = this.data["city"];
      }else if(!this.data["city"]){
        title = this.data["region"];
      }
      return title;
    },
    init_image(){
      return this.data["header"] != "" ? this.data["header"] : this.default_url
    }
  },
  methods:{
    fileInput(event){
      // uploading file to an existingOpp
      let formData = new FormData();
      formData.append("header", event.target.files[0]);
      formData.append("location_code", this.data.location_code);
      if (event.target.files[0] !== undefined) {
        this.$http.post('/api/v1/agencies/upload_header', formData, {
          headers: {"Content-type": "multipart/form-data"}
        }).then(response => {
          if(response.data.status == true){
            this.data['header'] = response.data.url;
          }
        })
      }
    },
  }
}
</script>
