const router = [
  {
    path: '/opportunities',
    name: 'OpportunityList',
    component: () => import('./index'),
    redirect: '/opportunities/main',
    children: [
      {
        path: 'main',
        name: 'OpportunityMain',
        component: () => import('./main'),
      },
      {
        path: '/opportunities/my_agency',
        name: 'OpportunityMyAgency',
        component: () => import('./myAgency'),
      },
      {
        path: '/opportunities/detail/:uuid',
        name: 'Detail',
        component: () => import('./detail'),
      },
      {
        path: '/opportunities/edit/:uuid',
        name: 'OpportunityEdit',
        component: () => import('./edit'),
      },
      {
        path: '/opportunities/intro',
        name: 'OpportunityIntro',
        component: () => import('@/components/opportunities/intro'),
      },
      {
        path: '/opportunities/permissions',
        name: 'OpportunityPermission',
        component: () => import('@/components/opportunities/permission'),
      },
      {
        path: '/opportunity_organizations',
        name: 'OpportunityOrganization',
        component: () => import('@/components/opportunity_organizations/index'),
      },
      {
        path: '/opportunity_organizations/reporting',
        name: 'OpportunityOrganizationReporting',
        component: () => import('@/components/opportunity_organizations/reporting'),
      },
      {
        path: '/opportunities/forbidden',
        name: 'OpportunityForbidden',
        component: () => import('@/components/opportunities/forbidden'),
      },
    ]
  },
  {
    path: '/my_agency',
    name: 'MyAgency',
    component: () => import('@/components/opportunities/myAgency'),
  },
  {
    path: '/new',
    name: 'OpportunityCreate',
    component: () => import('./new'),
  },
  {
    path: '/kanban',
    name: 'OpportunityKanban',
    component: () => import('@/components/opportunities/kanban'),
  },
  {
    path: '/hfm',
    name: 'HFM',
    component: () => import('@/components/opportunities/hfm'),
  },
  {
    path: '/opportunity-flow',
    name: 'OpportunityFlow',
    component: () => import('@/components/opportunities/OpportunityFlow'),
  },
  {
    path: '/general-dashboard',
    name: 'NewGeneralDashboard',
    component: () => import('@/components/opportunities/pdf/GeneralDashboard'),
  },
  {
    path: '/new-opportunity-form',
    name: 'NewOpportunityForm',
    component: () => import('@/components/opportunities/pdf/NewOpportunityForm'),
  },
  {
    path: '/networking',
    name: 'Networking',
    component: () => import('@/components/opportunities/pdf/Networking'),
  },
  {
    path: '/kan-ban-view',
    name: 'KanBanView',
    component: () => import('@/components/opportunities/pdf/KanBanView'),
  },
  {
    path: '/my-agency-dashboard',
    name: 'MyAgencyDashboard',
    component: () => import('@/components/opportunities/pdf/MyAgencyDashboard'),
  },
  {
    path: '/my-personal-dashboard',
    name: 'MyPersonalDashboard',
    component: () => import('@/components/opportunities/pdf/MyPersonalDashboard'),
  },
  {
    path: '/HFM-integration',
    name: 'HFMIntegration',
    component: () => import('@/components/opportunities/pdf/HFMIntegration'),
  },
  {
    path: '/Insights',
    name: 'Insights',
    component: () => import('@/components/opportunities/pdf/Insights'),
  },
]
export default router
