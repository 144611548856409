import * as Vuex from 'vuex'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from './config/oidc'
import axios from 'axios'

export default Vuex.createStore({
  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      // NOTE: If you do not want to use localStorage for tokens, in stead of just passing oidcSettings, you can
      // spread your oidcSettings and define a userStore of your choice
      // {
      //   ...oidcSettings,
      //   userStore: new WebStorageStateStore({ store: window.sessionStorage })
      // },
      // Optional OIDC store settings
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      }
      // Optional OIDC event listeners
      // {
      // userLoaded: (user) => console.log('OIDC user is loaded:', user),
      // userUnloaded: () => console.log('OIDC user is unloaded'),
      // accessTokenExpiring: () => console.log('Access token will expire'),
      // accessTokenExpired: () => console.log('Access token did expire'),
      // silentRenewError: () => console.log('OIDC user is unloaded'),
      // userSignedOut: () => console.log('OIDC user is signed out'),
      // oidcError: (payload) => console.log('OIDC error', payload),
      // automaticSilentRenewError: (payload) => console.log('OIDC automaticSilentRenewError', payload)
      // }
    ),
  },
  state: {
    requestLoading: 0,
    snackbar: { color: '', text: '', timeout: 3000 },
    opportunityListItems: {},
    permissions: { needLoad: true, unfinished_opportunity_list: [] },
    categoryListItems: [],
    milestones: '',
  },
  getters: {
    getOpportunityListItems: (state) => state.opportunityListItems,

    permissions: (state) => state.permissions,
    getCategoryListItems: (state) => state.categoryListItems,
  },
  mutations: {
    setOpportunityListItems(state, listItems) {
      state.opportunityListItems = listItems
    },
    setPermissions(state, permissions) {
      state.permissions = permissions
    },
    refreshPhoto(state, permissions) {
      state.permissions.facebook_avatar = permissions.facebook_avatar + '&rand=' + Date.now()
    },
    SET_LOADING: (state, boolean) => {
      boolean ? ++state.requestLoading : --state.requestLoading
    },
    SHOW_MESSAGE(state, payload) {
      state.snackbar = payload
    },
    is_milestones_closed(boolean) {
      boolean
    },
    setCategoryListItems: (state, list) => {
      state.categoryListItems = list
    },
  },
  actions: {
    permissions: ({ commit, state }) => {
      if (state.permissions.needLoad) {
        axios
          .get(
            process.env.VUE_APP_COLLECTIVE_APIS_URL +
              '/api/v1/employees/profile?noloading=true'
          )
          .then((res) => {
            commit('setPermissions', res.data)
          })
      }
    },
    SetLoading({ commit }, boolean) {
      commit('SET_LOADING', boolean)
    },
    showSnack({ commit }, payload) {
      //payload {color: '', text：'', timeout: 5000}
      commit('SHOW_MESSAGE', payload)
    },
    isMilestonesClosed({ commit }, payload){
      commit("is_milestones_closed", payload);
    },
  },
})
