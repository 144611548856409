const router = [
  {
    path: '/employee/show/:uid',
    name: 'Employee',
    component: () => import('./index'),
  },
  {
    path: '/employee/questionnaire',
    name: 'EmployeeQuestionnaire',
    component: () => import('./questionnaire/main'),
  }
  ,
  {
    path: '/employee/profile',
    name: 'EmployeeProfile',
    component: () => import('./profile'),
  }
]
export default router
