const router = [
  {
    path: '/collections',
    name: 'CollectionList',
    component: () => import('./index'),
    redirect: '/collections/main',
    children: [
      {
        path: 'main',
        name: 'CollectionMain',
        component: () => import('./main'),
      },
      {
        path: '/collections/:uuid',
        name: 'Collection',
        component: () => import('./show'),
      },
    ]
  },
]
export default router
