<template>
  <div>
    <h1 class="mt-2 mb-5">GLP Alumni</h1>
    <v-row class="pitch_team_member_container flex justify-start">
      <div
        v-for="user in data"
        :key="user.employee.uid"
        class="members text-center v-col-3"
      >
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <router-link
              :to="{ name: 'Employee', params: { uid: user.employee.id } }"
            >
              <div v-if="user.employee.profile_pic_url" v-bind="props">
                <img :src="user.employee.profile_pic_url + '&width=120'" />
              </div>
              <div v-else class="icon-user" v-bind="props"></div>
              <h3>{{ user.employee.full_name }}</h3>
              <p>{{ user.employee.title }}</p>
            </router-link>
          </template>
          <span>
            <div v-for="alumni in user.alumnis" :key="alumni.uuid">
              {{ alumni.year }} {{ alumni.program_name }}
            </div>
          </span>
        </v-tooltip>
      </div>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
    };
  },
  async created() {
    const location_code = this.$route.params.location_code;
    this.$http
      .get(
        "/api/v1/agencies/show_tab?tab_name=talent&location_code=" +
          location_code +
          ""
      )
      .then((response) => {
        this.data = response.data;
      });
  },
};
</script>
