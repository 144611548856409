<template>
  <div>
    <v-checkbox
      v-model="checkbox"
      :label="`Select All People`"
      @update:modelValue='changeSelectAll()'
      class="float-left my-3"
      hide-details
      color="blue-darken-2"
    ></v-checkbox>
    <v-btn size="small" color="#ffce00" class="float-right my-3 ml-2" @click="showAll()">Show All</v-btn>
    <v-btn size="small" color="#ffce00" class="float-right my-3" @click="newCollection()">Create Collection</v-btn>
    <v-data-table
      :headers="headers"
      :items="data"
      class="search_table"
      v-model:page="page"
      :items-per-page="itemsPerPage"
      :disable-pagination="!showPage"
      hide-default-footer
      fixed-header
      @page-count="pageCount = $event"
      show-select
      v-model="selected"
      item-value="uid"
      checkbox-color= "#1976d2"
    >
      <template v-slot:[`item.name`]="{item}">
        <div class="detail">
          <div class="inline-middle agency_bg icon-user mr-2">
            <div></div>
          </div>
          <div class="inline-middle detail mt-1" @click="recordSearch(item, 'employee')">
            <router-link
              :to="{
                name: 'Employee',
                params: { uid: item.selectable.id },
              }"
            >
              {{ item.selectable.full_name }}
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:[`item.agency`]="{ item }">
        <div @click="recordSearch(item, 'agencies')">
          <router-link :to="{ name: 'Agency', params: { location_code: item.selectable.location_code }, query: { search_id: search_id }}">
            {{ item.selectable.agency }}
          </router-link>
        </div>
      </template>
      <template v-slot:bottom>
        <div class="text-center pagination pt-2" v-if="showPage">
          <v-pagination
            v-model="page"
            :length="pageCount"
            total-visible="7"
            density="comfortable"
            active-color="blue-darken-2"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
    <formDialog ref="newDialog" :title="'New Collection'" @setValue="createCollection" />
  </div>
</template>
<script>
import formDialog from '@/components/collections/formDialog';
export default {
  data() {
    return {
      selected: [],
      page: 1,
      itemsPerPage: 6,
      checkbox: false,
      headers: [
        { title: "Name", key: "name" },
        { title: "Email", key: "email" },
        { title: "Title", key: "title" },
        { title: "Agency", key: "agency" },
      ],
      showPage: true
    };
  },
  components: {formDialog},
  props: {
    data: null,
    search_id: null,
    keywords: null,
  },
  watch: {
    selected(val){
      this.checkbox = val.length == this.data.length
    },
    keywords(newVal, oldVal){
      if (newVal != oldVal) {
        this.selected = []
      }
    }
  },
  methods: {
    changeSelectAll(){
      this.selected = this.checkbox ? this.data.map(item => {return item.uid}) : [];
    },
    newCollection(){
      if (this.selected.length == 0) {
        this.$store.dispatch('showSnack', {text: 'Please select at least one employee', color: 'red', timeout: 5000});
      } else {
        this.$refs['newDialog'].dialog = true;
        this.$refs['newDialog'].name = this.keywords;
      }
    },
    createCollection(data){
      this.$http.post('/api/v1/collections/create_and_add_members', {
        form: {
          name: data.name,
          type: data.type,
          visibility: data.visibility,
          members: this.selected,
        }
      })
        .then(response => {
          if (response.data.status == 'success'){
            this.$refs['newDialog'].dialog = false;
          }
        })
    },
    recordSearch: function(item, type){
      let item_id, item_name;
      if (type == 'employee') {
        item_id = item.selectable.id;
        item_name = item.selectable.full_name;
      } else {
        item_id = item.selectable.location_code;
        item_name = item.selectable.agency;
      }
      this.$http.post('/api/v1/search/record_global_search?noloading=true&selected_type=' + type + '&search_id='+ this.search_id + '&selected_id=' + item_id + '&selected_name=' + item_name )
    },
    showAll () {
      this.showPage = false
      this.itemsPerPage = -1
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.data.length / this.itemsPerPage)
    },
  },
};
</script>
