<template>
  <v-dialog v-model="dialog" persistent max-width="600px" >
    <v-card class="edit-dialog">
      <v-card-title>
        <span class="text-h5 text-capitalize">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
        <v-form
          ref="form"
          v-model="valid"
          :lazy-validation="false"
        >

          <v-text-field
           label="Name"
           v-model="name"
           :counter="20"
           :rules="name_rule()"
           required
           variant="underlined"
          ></v-text-field>

          <v-select
            v-model="type"
            :items="types"
            label="Collections Type"
            required
            variant="underlined"
          ></v-select>

          <v-select
            v-model="visibility"
            :items="visibilities"
            label="Visibility"
            required
            variant="underlined"
          ></v-select>

          <br>
        </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="updateVal"
          variant="text"
        >
          Save
        </v-btn>

        <v-btn
          color="grey"
          class="mr-4 bg-error"
          @click="dialog = false"
          variant="text"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  emits: ["setValue"],
  data() {
    return {
      title: null,
      dialog: false,
      valid: true,
      types: [
        'Employee'
      ],
      visibilities: [
        'Public',
        'Private'
      ],
      name: null,
      type: null,
      visibility: null,
    }
  },
  async created(){
    this.type = this.types[0];
    this.visibility = this.visibilities[0];
  },
  props: {
    collection_name: null,
    collection_type: null,
    collection_visibility: null,
  },
  watch: {
    collection_name: function(val){
      this.name = val ? val : null
    },
    collection_type: function(val){
      this.type = val ? val : this.types[0]
    },
    collection_visibility: function(val){
      this.visibility = val ? val : this.visibilities[0]
    },
  },
  methods: {
    name_rule(){
      return [v => !!v || 'Name is required', v => (v && v.length <= 20) || 'Name must be less than 20 characters'];
    },
    updateVal() {
      this.$emit('setValue', {type: this.type, name: this.name, visibility: this.visibility})
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.edit-dialog.v-card) {
  .v-card-text {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .v-input {
    font-size: 12px;
    height: 63px;
    font-family: $Archivo;
    .v-input__control {
      .v-field {
        .v-field__field {
          margin-top: 10px;
          & > input {
            color: rgba(0,0,0,.87);
            max-height: 32px;
            padding: 8px 0 8px;
            font-size: 12px;
            min-height: inherit;
            display: block;
          }
          .v-label {
            top: 6px;
            height: 15px;
            line-height: 15px;
          }
          .v-field__input {
            color: rgba(0,0,0,.87);
            max-height: 32px;
            padding: 8px 0 0;
            font-size: 12px;
            min-height: inherit;
            display: flex;
            input {
              -webkit-box-flex: 1;
              flex: 1 1;
              position: relative;
            }
          }
        }
        .v-field__append-inner {
          margin-top: 10px;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
  .v-card-actions {
    padding: 15.5px 24px 36px 24px;
    .flex-grow-1 {
      flex-grow: inherit !important;
    }
    button.v-btn--disabled {
      background-color: rgba(0,0,0,.12)!important;
      color: #666 !important;
      opacity: 1;
    }
    button.bg-error {
      background-color: #ff5252 !important;
      border-color: #ff5252 !important;
      .v-btn__content {
        color: #fff;
      }
    }
  }
}
</style>