<template>
  <div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Logout',
  async created() {
    await this.logout();
  },
  computed: {
    ...mapGetters('oidcStore', [
      'oidcIdToken',
    ])
  },
  methods:{
    ...mapActions('oidcStore', [
      'removeOidcUser'
    ]),
    async logout () {
      const redirect_url = (document.referrer || this.$router.internalHistory[0]);
      const url = process.env.VUE_APP_OAUTH2_ISSUER + "/oauth2/logout?id_token_hint=" + this.oidcIdToken +"&post_logout_redirect_uri=" + redirect_url;
      await this.removeOidcUser().then(() => {
        // Navigate back to home
        window.location = url;
      })
    }
  }
}
</script>
