<template>
  <div class="content-form float-right">
    <actionButton
      :is_submitted="is_submitted"
      :can_submit="can_submit"
      :is_market_leader="is_market_leader"
      :is_ww_leader="is_ww_leader"
      :show_no_nominee_text="show_no_nominee_text"
      :nominations="nominationData.nominations"
      :market_id="nominationData.market_id"
      @refreshData="getData"
    />
    <div v-if="program.cost">
      <div class="v-row float-right" style="margin-right: -4px">
        <v-chip class="ma-1" color="#6D6D6D" variant="outlined"
          >{{ program.currency_symbol }}
          {{ program.participant_cost }}/participant</v-chip
        >
        <v-chip class="ma-1" color="#6D6D6D" variant="outlined"
          >Total: {{ program.currency_symbol }}
          {{ nominationData.nominations.length * program.participant_cost }} ({{
            nominationData.nominations.length
          }}
          {{ show_participants }})</v-chip
        >
        <v-chip
          class="ma-1"
          color="#6D6D6D"
          variant="outlined"
          v-if="nominationData.can_add && !is_ww_leader"
          >{{
            nominationData.limit > nominationData.nominations.length
              ? nominationData.limit - nominationData.nominations.length
              : 0
          }}
          spots left ({{ nominationData.limit }} total)</v-chip
        >
      </div>
      <div class="clear"></div>
    </div>
    <section class="nomination_form nominee new_nomination_form">
      <list
        v-model="currentNominationsList"
        :canAdd="can_add"
        category="current"
        @addNomination="addCurrentList"
        @openNominationDialog="deleteNominationDialog"
      ></list>
    </section>

    <div class="new_nomination_form_box">
      <div
        v-if="
          nominationData.limit >= 5 || nominationData.nominations.length >= 3
        "
      ></div>
      <h3
        v-if="nominationData.limit != 0 || alternativeNominationsList.length > 0"
        class="new_nomination_form_box_title"
      >
        Alternate Candidates
      </h3>
    </div>
    <section class="nomination_form nominee new_nomination_form">
      <list
        v-model="alternativeNominationsList"
        :canAdd="can_add"
        category="alternative"
        :notRemove="
          is_submitted &&
          nominationData.limit <= nominationData.nominations.length
        "
        @addNomination="addAlternativeList"
        @openNominationDialog="deleteNominationDialog"
      ></list>
    </section>
    <v-dialog v-model="deleteDialog" persistent max-width="506px">
      <v-card>
        <v-card-title>
          <span class="text-h5 text-capitalize">Remove</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            Are you sure you want to remove this employee from this nomination?
          </v-container>
        </v-card-text>
        <v-card-actions class="pa-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="deleteNominee()"
            >YES</v-btn
          >
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="deleteDialog = false"
            >CANCEL</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reminderDialog" max-width="360">
      <v-card class="list_model_dialog">
        <v-card-title> </v-card-title>
        <v-card-text>
          Finished listing all your nominees? <br />
          Remember to click Done! Before you go.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="list_model_box">
            <v-btn
              color="green-darken-1"
              variant="text"
              @click="cancelRedirectTo()"
            >
              OK
            </v-btn>
            <v-btn color="green-darken-1" variant="text" @click="redirectTo()">
              Keep Going
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss" scoped>
@import "~@/assets/styles/app/nominations.scss";
</style>
<script>
import List from "./list/list.vue";
import actionButton from "./button/button.vue";
export default {
  emits: ["activeTab"],
  inheritAttrs: false,
  components: { actionButton, List },
  data() {
    return {
      approve: false,
      reminderDialog: false,
      deleteDialog: false,
      nomination_params: {},
      nominationData: { nominations: [], alternative_nominations: [] },
      redirectUrl: "",
      currentNominationsList: [],
      alternativeNominationsList: [],
      category: null,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.approve ||
      to.name == "NominationForm" ||
      to.name == "Nominationmain" ||
      to.name == "NominationStatus" ||
      this.is_submitted ||
      (!this.is_submitted && !this.is_market_leader) ||
      this.nominationData.limit == 0
    ) {
      next();
      this.approve = false;
    } else {
      this.reminderDialog = true;
      this.redirectUrl = to;
    }
  },
  computed: {
    show_participants() {
      return this.nominationData.nominations.length <= 1
        ? "participant"
        : "participants";
    },
    is_submitted() {
      return this.nominationData.status;
    },
    is_approved() {
      return this.nominationData.is_approved;
    },
    can_submit() {
      return (
        this.nominationData.can_submit &&
        (this.is_market_leader || this.nominationData.market_has_submitted)
      );
    },
    can_add() {
      return this.nominationData.can_add;
    },
    market_has_submitted() {
      return this.nominationData.market_has_submitted;
    },
    is_market_leader() {
      return this.nominationData.is_glp_market_leader;
    },
    is_ww_leader() {
      return this.nominationData.is_ww_leader;
    },
    show_no_nominee_text() {
      return (
        this.nominationData.nominations.length == 0 &&
        this.nominationData.limit == undefined
      );
    },
  },
  props: {
    program: {},
    tabInfo: {},
  },
  created() {
    this.getData();
  },
  methods: {
    redirectTo() {
      this.approve = true;
      this.$router.push({ name: this.redirectUrl.name });
    },
    addCurrentList(value) {
      this.updateCategory(
        this.currentNominationsList[value.newDraggableIndex].nomination_id,
        "current"
      );
      if (this.currentNominationsList.length > this.nominationData.limit) {
        this.removeNullNomination(this.currentNominationsList);
      }
    },
    addAlternativeList(value) {
      this.updateCategory(
        this.alternativeNominationsList[value.newDraggableIndex].nomination_id,
        "alternative"
      );
      if (this.nominationData.limit > this.currentNominationsList.length) {
        let emptyLength = this.currentNominationsList.filter(
          (item) => item.nomination_id === null
        ).length;

        if (this.currentNominationsList.length < 5 || emptyLength === 0) {
          this.currentNominationsList.push({ nomination_id: null });
        }
      }
    },
    currentNominations() {
      let count = this.nominees_column(this.nominationData.nominations);
      let list = [...this.nominationData.nominations];
      for (let i = 0; i < count; i++) {
        list.push({ nomination_id: null });
      }
      return list;
    },
    alternativeNominations() {
      if (this.can_add) {
        return this.nominationData.alternative_nominations.concat({
          nomination_id: null,
        });
      } else {
        return this.nominationData.alternative_nominations;
      }
    },
    cancelRedirectTo() {
      this.reminderDialog = false;
      if (this.tabInfo["newTab"] == this.tabInfo["tabCount"] - 1) {
        this.$emit("activeTab", this.tabInfo["oldTab"]);
      }
    },
    nominees_column(nomination) {
      // if limit < 5 && limit - nomination > 0 return limit - nomination
      // if limit >= 5 return limit - nomination > 0
      if (!this.can_add) {
        return 0;
      } else {
        if (this.nominationData.limit >= 5) {
          if (nomination.length >= 5) {
            return this.nominationData.limit - nomination.length > 0 ? 1 : 0;
          } else {
            return 5 - nomination.length;
          }
        } else {
          return this.nominationData.limit - nomination.length > 0
            ? this.nominationData.limit - nomination.length
            : 0;
        }
      }
    },

    deleteNominationDialog(val) {
      this.category = val.category;
      this.deleteDialog = true;
      this.nomination_params = { uid: val.id, index: val.index };
    },
    deleteNominee() {
      this.deleteDialog = false;
      this.$http
        .delete("/api/v1/nominations/" + this.nomination_params.uid)
        .then((response) => {
          this.nominationData = response.data.nominationData;
          this.currentNominationsList = this.currentNominations();
          this.alternativeNominationsList = this.alternativeNominations();
          this.nomination_params = {};
        });
    },
    submitNominees() {
      this.$http
        .post("/api/v1/nominations/submit", {
          program_id: this.$route.params.id,
          market_id: this.nominationData.market_id,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.nominationData.status = true;
          }
        });
    },
    removeNullNomination(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i]["nomination_id"] === null) {
          data.splice(i, 1);
          break;
        }
      }
    },
    getData() {
      this.$http
        .get(
          "/api/v1/nominations/nomination_list?program_id=" +
            this.$route.params.id
        )
        .then((response) => {
          this.nominationData = response.data.nominationData;
          this.currentNominationsList = this.currentNominations();
          this.alternativeNominationsList = this.alternativeNominations();
        });
    },
    updateCategory(uuid, category) {
      this.$http
        .post("/api/v1/nominations/update_category", {
          uuid: uuid,
          category: category,
        })
        .then((response) => {
          this.nominationData = response.data.nomination_data;
          this.currentNominationsList = this.currentNominations();
          this.alternativeNominationsList = this.alternativeNominations();
        });
    },
  },
};
</script>
