<template>
  <div class="pt-4 status_box">
    <div class="status_excel_area">
      <h2 style="padding: 0px 10px 0 0">Nomination Status</h2>
      <div class="status_excel_area_right">
        <div class="status_excel_area_box" v-if="is_worldwide_leader">
          <div class="status_excel_area_info">
            <span class="status_excel_area_icon status_excel_area_blue"></span>
            <span class="status_excel_area_caption">GTT has approved.</span>
          </div>
          <div class="status_excel_area_info">
            <span class="status_excel_area_icon status_excel_area_green"></span>
            <span class="status_excel_area_caption">ML has submitted, waiting for GTT approve.</span>
          </div>
          <div class="status_excel_area_info">
            <span class="status_excel_area_icon status_excel_area_red"></span>
            <span class="status_excel_area_caption">ML has not submitted yet.</span>
          </div>
        </div>
        <button
          type="button"
          @click="onexport"
          v-if="is_worldwide_leader"
          class="status_excel_btn"
        >
          Export
        </button>
      </div>
    </div>
    <div class="search-results market-table status_table">
      <div class="status_table_section" @mousewheel.passive="mouseWheel">
        <table v-if="is_worldwide_leader">
          <thead>
            <tr>
              <th
                class="text-left normal_width_isLeader"
                v-for="(item, i) in columns"
                :key="i + item"
                rowspan="2"
              >
                {{ item }}
              </th>
              <th
                class="text-center"
                v-for="(program, i) in nominees"
                :key="i"
                colspan="3"
              >
                {{ program }}
              </th>
            </tr>
            <tr>
              <template v-for="(item, i) in nominees" :key="i + '4'">
                <th style="width: 4%">Status</th>
                <th class="text-left" style="width: 12%">Nominators</th>
                <th class="text-left status_th" style="width: 12%">Nominees</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in nomination_status" :key="i + item">
              <td>
                <span class="market_name">{{ item.column_a }}</span>
                <button
                  class="market_send"
                  @click="show_notification(item.uuid)"
                  v-if="item.show_ceo_notification"
                >
                  <v-icon>mdi-send</v-icon>SEND
                </button>
              </td>
              <template
                v-for="program in nominees"
                :key="'nominators' + program"
              >
                <td
                  class="text-center"
                  :class="status_color(item[program]?.status)"
                >
                  <v-chip
                    size="x-small"
                    :color="status_color(item[program]?.status)"
                    style="height: 18px"
                  >
                    {{ item[program]?.limit }}
                  </v-chip>
                  <button class="account-group" v-if="item[program].alternative?.length > 0 || item[program].next?.length > 0" @click="showAlternatives(item[program], item.column_a, program)">
                    <v-icon>mdi-account-group</v-icon>
                  </button>
                </td>
                <td colspan="2" class="status_td">
                  <v-row
                    :key="nominees + nominator"
                    v-for="(nominees, nominator) in item[program]?.current.nominators"
                    style="display: flex"
                  >
                    <v-col
                      cols="6"
                      align-self="start"
                      style="word-break: break-all; padding: 8px 0"
                      >{{ nominator }}</v-col
                    >
                    <v-col cols="6" align-self="center">
                      <div
                        style="
                          word-break: break-all;
                          background: #efefef;
                          border-radius: 4px;
                          margin-bottom: 4px;
                          display: inline-block;
                        "
                      >
                        <template
                          v-for="(nominee, _index) in nominees"
                          :key="nominee + _index"
                        >
                          <span class="pa-2"> {{ nominee }}</span>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
        <table v-else>
          <thead>
            <tr class="status_table_title">
              <th
                class="text-left normal_width"
                v-for="(item, i) in columns"
                :key="i + item"
              >
                {{ item }}
              </th>
              <th class="text-center" v-for="(program, i) in nominees" :key="i">
                {{ program }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in nomination_status"
              :key="i + item"
              class="status_table_tr"
            >
              <td>{{ item.column_a }}</td>
              <td
                class="text-center"
                v-for="(program, j) in nominees"
                :key="j + program"
              >
                <span :style="item[program][1]">{{ item[program][0] }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <nomination-status-send-email-dialog v-model:value="emailDialog" v-if="emailDialog" :uuid="emailMarketId"/>
      </div>
    </div>
    <v-dialog v-model="alternativesDialog" persistent max-width="1000px" >
      <v-card class="alternatives-dialog">
        <v-card-title class="alternatives-dialog-title">
          {{ alternativesDialogItems.program }}({{ alternativesDialogItems.market }})
          <v-icon
            color="#fff"
            class="alternatives_dialog_close"
            @click="alternativesDialog = false"
            >mdi-close</v-icon>
        </v-card-title>
          <v-card-text>
            <div class="alternatives-dialog-block">
              <div class="alternatives-dialog-area" v-for="(items, nominationType) in alternativesDialogItems.data" :key="nominationType">
                <h4>{{ nominationType }}</h4>
                <ul class="alternatives-dialog-list" v-if="items.length > 0">
                  <li class="alternatives-dialog-item" v-for="item in items" :key="item.email">
                    <div class="alternatives-dialog-info">
                      <img :src="item.photo" />
                      <div class="alternatives-dialog-box">
                        <span class="alternatives-dialog-nominees">{{ item.email }}</span>
                        <span class="alternatives-dialog-nominators">nominate by {{ item.nominator }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import NominationStatusSendEmailDialog from './NominationStatusSendEmailDialog.vue'
export default {
  components: {
    NominationStatusSendEmailDialog,
  },
  data() {
    return {
      nomination_status: [],
      columns: [],
      nominees: {},
      is_worldwide_leader: false,
      emailDialog: false,
      ceo_notification: true,
      email_body: null,
      email_from: 'no-reply-collective@tbwa.com',
      email_to: null,
      email_cc: null,
      email_subject: null,
      alternativesDialog: false,
      alternativesDialogItems: {program: "", market: "", data: {"Alternatives": [], "Next Season": []}},
    }
  },
  created() {
    this.getData()
  },
  computed: {
    empty_email() {
      return this.email_body == '<p><br class="ProseMirror-trailingBreak"></p>'
    },
  },
  methods: {
    onexport() {
      // On Click Excel download button
      this.getExportNomination().then((res) => {
        // export json to Worksheet of Excel
        // only array possible
        const sheet_data = XLSX.utils.json_to_sheet(res)
        // A workbook is the name given to an Excel file
        const wb = XLSX.utils.book_new() // make Workbook of Excel
        // add Worksheet to Workbook
        // Workbook contains one or more worksheets
        XLSX.utils.book_append_sheet(wb, sheet_data, 'Sheet1') // sheetAName is name of Worksheet
        // export Excel file
        XLSX.writeFile(wb, this.export_file_name()) // name of the file is 'book.xlsx'
      })
    },
    onEditorChange() {
      this.email_body = this.$refs.toastuiEditor.invoke('getHTML')
    },
    send_ceo_notification() {
      let emailTo = []
      let emailCc = []
      for (
        let i = 0;
        i < this.$refs.multiEmployeeSearchEmailTo.employee.length;
        i++
      ) {
        emailTo.push(this.$refs.multiEmployeeSearchEmailTo.employee[i].email)
      }
      for (
        let i = 0;
        i < this.$refs.multiEmployeeSearchEmailCc.employee.length;
        i++
      ) {
        emailCc.push(this.$refs.multiEmployeeSearchEmailCc.employee[i].email)
      }
      this.$http
        .post('/api/v1/notifications/send_ceo_notification', {
          email_body: this.email_body,
          email_from: this.email_from,
          email_to: emailTo.join(','),
          email_cc: emailCc.join(','),
          email_subject: this.email_subject,
        })
        .then((response) => {
          if (response.data.status == 'success') {
            this.ceo_notification = false
          }
        })
    },
    show_notification(uuid) {
      this.emailDialog = true
      this.emailMarketId = uuid
    },
    mouseWheel(e) {
      if (e.wheelDeltaY || e.detailY) {
        if (e.wheelDeltaY > 0 || e.detailY < 0) {
          this.$parent.scrollClass = ''
        }
        if (e.wheelDeltaY < 0 || e.detailY > 0) {
          this.$parent.scrollClass = 'hideClass'
        }
      }
    },
    getData() {
      this.$http.get('/api/v1/nominations/status').then((response) => {
        this.nomination_status = response.data.nomination_status.status
        this.columns = response.data.nomination_status.columns
        this.nominees = response.data.nominees
        this.is_worldwide_leader = response.data.is_worldwide_leader
      })
    },
    status_color(status) {
      if (status == 'Not Submitted') {
        return 'notSubmitted'
      } else if (status == 'Submitted') {
        return 'submitted'
      } else if (status == 'Approved') {
        return 'approved'
      } else {
        return 'others'
      }
    },
    async getExportNomination() {
      const response = await this.$http.get(
        '/api/v1/nominations/export_submit_nomination'
      )
      return response.data
    },
    export_file_name() {
      const d = new Date();
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day
      return [year, month, day].join('-') + '_Nomination.csv'
    },
    showAlternatives(items, market, program) {
      this.alternativesDialog = true;
      this.alternativesDialogItems.market = market;
      this.alternativesDialogItems.program = program;
      this.alternativesDialogItems.data["Alternatives"] = items.alternative;
      this.alternativesDialogItems.data["Next Season"] = items.next;
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app/nominations.scss';
</style>
