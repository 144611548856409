<template>
  <v-breadcrumbs :items="items" divider=">">
    <template v-slot:title="{ item }">
      <template v-if="item.name">
        <router-link :to="{ name: item.name, query: item.queryParams }">{{ item.text }}</router-link>
      </template>
      <template v-else>{{ item.text }}</template>
    </template>
  </v-breadcrumbs>
</template>
<script>
export default {
  props: { items: null },
};
</script>
<style lang="scss" scoped>
.v-breadcrumbs {
  font-size: 12px;
  line-height: 14px;
  :deep(.v-breadcrumbs-item) {
    color: #FECC00;
    padding: 0;
    line-height: 14px;
    a {
      color:#818386;
      padding: 0;
    }
  }
  :deep(.v-breadcrumbs-item--disabled) {
    opacity: 1;
    color:#FECC00;
  }
  :deep(.v-breadcrumbs-divider) {
    padding: 0 3px;
    color: rgba(0,0,0,.38);
    font-size: 14px;
  }
}
</style>