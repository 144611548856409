<template>
  <div class="control">
    <v-autocomplete
      v-model="employee"
      :items="items"
      hide-selected
      :hide-no-data="false"
      color="lighten-2"
      item-title="text"
      item-value="value"
      :label="label"
      :placeholder="placeholder"
      :loading="isLoading"
      v-model:search="employeeSearch"
      :rules="[regulations]"
      variant="underlined"
    >
      <template v-slot:selection="data">
        {{ data.item?.value?.name }}
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  data() {
    return {
      employeeSearch: null,
      employee: null,
      items: [],
      isLoading: false,
      cancelSource: null,
      baseURL: '/api/v1/employees/autocomplete?noloading=true&',
      checkbox: true,
      regulations: true,
    }
  },
  props: {
    selectedEmployee: null,
    exitUsers: null,
    userType: null,
    rules: null,
    placeHolder: null,
    params: null,
    label: null,
  },
  computed: {
    placeholder: function () {
      return this.placeHolder ? this.placeHolder : 'Please input name or email'
    },
    url: function () {
      return this.params
        ? this.baseURL + this.params.join('&') + '&'
        : this.baseURL
    },
  },
  watch: {
    selectedEmployee: function (val) {
      if (val) {
        this.items = [{ text: val.text, value: val.value }]
        this.employee = val.value
      }
    },
    rules: function (val){
      this.regulations = val;
    },
    employeeSearch: function (val) {
      if (!val || val.length < 3) {
        return
      }
      this.isLoading = true
      this.cancelRequest()
      this.cancelSource = this.$http.CancelToken.source()
      this.$http
        .get(this.url + 'keywords=' + val, {
          cancelToken: this.cancelSource.token,
        })
        .then((response) => {
          if (response && response.data.length > 0) {
            if (this.exitUsers && this.exitUsers.length > 0) {
              this.items = response.data.filter(
                (item) => !this.exitUsers.some((it) => it.uid == item.value.uid)
              )
            } else {
              this.items = response.data
            }
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    employee: function (val) {
      this.$emit('setValue', { type: this.userType, val: val })
    },
  },
  methods: {
    cancelRequest() {
      if (this.cancelSource) {
        this.cancelSource.cancel('cancel request')
      }
    },
    clearAutocomplete() {
      this.items = []
    },
  },
  emits: ['setValue'],
}
</script>
