<template>
  <div class="opportunities">
    <h1 class="mt-2 mb-5">Opportunities</h1>
    <template v-if="message != null">
      <div class="oppo_title">
        <v-icon style="display: inline-flex;color:#1976d2;" >mdi-information</v-icon>
        <span class="ml-2" style="font-size:16px;">{{ message }}</span>
      </div>
    </template>

    <template v-else>
      <template v-if="series.length > 0">
        <div @click="goToOppList()">
          <div ref="chart" :style="{width: '100%', height: '300px'}"></div>
        </div>
      </template>
      <template v-else>
        <div class="mb-4 oppo_title">
          <v-icon style="display:inline-flex;color:#1976d2;" >mdi-information</v-icon>
          <span class="ml-2 mt-1" style="font-size:16px; ">There are no trend opportunities.</span>
        </div>
      </template>
      <template v-if="upcoming.length > 0">
        <v-divider class="mt-4 mb-8"></v-divider>
        <h1 class="mb-5 ml-8" style="margin-left:0 !important;">Upcoming opportunities</h1>
        <table class="pl-4 opportunities_table upcoming_opportunities_table">
          <thead>
            <tr>
              <th class="text-left">Brand</th>
              <th class="text-left">Size</th>
              <th class="text-left">Stage</th>
              <th class="text-left">Proposal date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in upcoming" :key="i" @click="goToOppShow(item.uuid)">
              <td>{{ item.brand }}</td>
              <td>{{ item.size }}</td>
              <td>{{ item.stage }}</td>
              <td>{{ item.proposal_date }}</td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <v-divider class="mt-4 mb-8"></v-divider>
        <div class="oppo_title">
          <v-icon style="display: inline-flex;color:#1976d2;" >mdi-information</v-icon>
          <span class="ml-2 mt-1" style="font-size:16px;">There are no upcoming opportunities.</span>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import { ref } from 'vue';
import echarts  from '@/utils/echarts'
export default {
  data() {
    return {
      xAxis: [],
      series: [],
      upcoming: [],
      message: null,
    }
  },
  setup() {
    const chart = ref(null);
    return {
      chart
    };
  },
  updated (){
    if (this.series.length > 0) this.drawLine();
  },
  async created(){
    const location_code = this.$route.params.location_code;
    this.$http.get('/api/v1/agencies/show_tab?tab_name=opportunities&location_code=' + location_code + '')
      .then(response => {
        if (response.data.message == null) {
          this.xAxis = response.data.x_axis;
          this.series = response.data.series;
          this.upcoming = response.data.upcoming;
        } else {
          this.message = response.data.message
        }
      })
  },
  methods: {
    goToOppList: function(){
      const locationCode = this.$route.params.location_code;
      const newUrl = `${process.env.VUE_APP_OPPHUB_URL}/opportunities?location_code=${locationCode}`;
      window.location.href = newUrl;
    },
    goToOppShow: function(uuid){
      const newUrl = `${process.env.VUE_APP_OPPHUB_URL}/opportunities/detail/${uuid}`;
      window.location.href = newUrl;
    },
    drawLine() {
      let chart = echarts.init(this.chart);
      let option = {
        legend: {
          orient: 'vertical',
          right: 10,
          top: 10,
          data: ['Wins', 'Total'],
          icon: 'arrow',
          textStyle: {
            rich: {
              line1: {
                height: 2,
                width: 60,
                backgroundColor: 'green'
              },
              line2: {
                height: 2,
                width: 60,
                backgroundColor: 'red'
              }
            }
          },
          formatter: function(name) {
            if (name === 'Wins') {
              return '{line1|} ' + name;
            } else if (name === 'Total') {
              return '{line2|} ' + name;
            }
          },
          data: [
            { name: 'Wins', icon: 'none' },
            { name: 'Total', icon: 'none' }
          ]
        },
        title: {
          text:'Last 6 months Opportunities'
        },
        tooltip: {},
        xAxis: {
          type: "category",
          data: this.xAxis
        },
        yAxis: {
          type: 'value'
        },
        series: this.series
      };
      chart.setOption(option);
      setTimeout(function() {
        window.onresize = function() {
          chart.resize();
        }
      }, 200)
    },
  }
}
</script>
