const router = [
  {
    path: '/resque_web',
    name: 'Resque',
    component: () => import('./index'),
    redirect: '/resque_web/overview',
    children: [
      {
        path: 'overview',
        name: 'ResqueOverview',
        component: () => import('./overview'),
      },
      {
        path: 'working',
        name: 'ResqueWorking',
        component: () => import('./working'),
      },
      {
        path: 'failed',
        name: 'ResqueFailed',
        component: () => import('./failed'),
      },
      {
        path: 'queues',
        name: 'ResqueQueues',
        component: () => import('./queues'),
      },
      {
        path: 'workers',
        name: 'ResqueWorkers',
        component: () => import('./workers'),
      },
      {
        path: 'status',
        name: 'ResqueStatus',
        component: () => import('./status'),
      },
    ]
  },
]
export default router
