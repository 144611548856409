<template>
  <div class="work_section">
    <component :is="'capabilities'"/>
    <component :is="'opportunities'"/>
    <component :is="'knowledge'"/>
  </div>
</template>

<script>
import capabilities from "./capabilities";
import opportunities from "./opportunities";
import knowledge from "./knowledge";
export default {
  components: {
    capabilities,
    opportunities,
    knowledge
  },
}
</script>
